.playlists {
    display: flex;
    align-items: center;
    &__content {
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        flex-direction: column;

        .song-name {
            font-size: $font-size-13;
            line-height: 1.3;
            margin-top: 5px;
            color: white;
        }

        &__status {
            flex-basis: 90px;

            image {
                margin-left: 15px;
                padding: 0;

                border: none;
                background: none;
            }
        }
    }

    &__box-ratio {
        height: 0;
        overflow: hidden;
        padding-top: 100%;
        position: relative;
        .main-image {
            position: absolute;
            top: 0%;
            left: 0%;
            width: 100%;
            height: 100%;
            border-radius: 8px;
            object-fit: cover;
            border-width: 1px;
            border-style: solid;
            border-color: #5e59646c;
        }
    }

    &__image {
        .thumbnail {
            width: 100%;
            min-width: 100%;
            height: 100%;
            flex-shrink: 0;
            object-fit: cover;
            border-radius: 6px;
            border-width: 1px;
            border-style: solid;
            border-color: #5e5964;
        }
        .avatar {
            width: 20px;
            height: 20px;
            flex-shrink: 0;
            object-fit: cover;

            border-radius: 10px;
        }
        .artist-name {
            font-size: $font-size-11;
            line-height: 1;
            text-align: left;
            display: inline-block;
            margin-top: 5px;
            margin-left: 5px;
            color: white;
        }
    }

    &__box-ratio-modal {
        height: 0;
        overflow: hidden;
        padding-top: 100%;
        background: transparent;
        position: relative;
        //width: 160px;
        .main-image {
            position: absolute;
            top: 0%;
            left: 0%;
            width: 100%;
            height: 100%;
            border-radius: 8px;
            object-fit: cover;
            border: solid 1px #5e59646c;
        }
    }

    // &__image-modal {
    //     .thumbnail {
    //         width: 100%;
    //         min-width: 100%;
    //         height: 40vw;
    //         flex-shrink: 0;
    //         object-fit: cover;
    //         border-radius: 6px;
    //         border-width: 1px;
    //         border-style: solid;
    //         border-color: #5e5964;
    //     }
    //     .avatar {
    //         width: 20px;
    //         height: 20px;
    //         flex-shrink: 0;
    //         object-fit: cover;

    //             border-radius: 10px;

    //     }
    //     .artist-name {
    //         font-size: $font-size-11;
    //         line-height: 1;
    //         text-align: left;
    //         display: inline-block;
    //         margin-top: 5px;
    //         margin-left: 5px;
    //         color: white;
    //     }
    // }
}

.archive {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 0;

    &__container {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .left-view {
            display: flex;
            align-items: center;
            flex-direction: row;
            width: 100%;

            .index {
                font-size: $font-size-13;
                line-height: 1.3;
                color: white;
                margin-right: 10px;
                min-width: 20px;
                text-align: center;
            }

            .pink-dot {
                margin-right: 10px;
                background-color: $pink;
                width: 10px;
                height: 10px;
                min-width: 10px;
                min-height: 10px;
                border-radius: 5px;
            }

            .empty-dot {
                margin-right: 10px;
                background-color: transparent;
                width: 10px;
                height: 10px;
                border-radius: 5px;
            }

            .image {
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: space-between;
                margin-right: 10px;
                background-color: transparent;

                .check {
                    width: 24px;
                    height: 24px;
                    object-fit: cover;
                    margin-right: 20px;
                    border: solid 1px $black--eight;
                    border-radius: 12px;

                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    justify-content: space-evenly;

                    .solid-red {
                        width: 24px;
                        height: 24px;
                        object-fit: cover;
                        border: solid 1px $black--eight;
                        border-radius: 12px;
                        background-color: red;
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        justify-content: space-evenly;
                        .line {
                            width: 10px;
                            height: 2px;
                            background-color: white;
                        }
                    }
                }

                .thumbnail {
                    width: 60px;
                    height: 60px;
                    flex-shrink: 0;
                    object-fit: cover;
                    border: solid 1px $black--eight;
                }

                .round {
                    border-radius: 30px;
                }
            }

            .info {
                display: flex;
                align-items: left;
                flex-direction: column;
                justify-content: space-around;
                width: 100%;
                .song-name {
                    font-size: $font-size-15;
                    line-height: 1.3;
                    color: white;

                    img {
                        width: 20px;
                        height: 20px;
                        object-fit: cover;
                        border: none;
                    }
                }
                .artist-name {
                    font-size: $font-size-13;
                    line-height: 1;
                    text-align: left;
                    display: inline-block;
                    margin-top: 5px;
                    color: $grey--four;
                }

                .read {
                    &-title {
                        font-size: $font-size-15;
                        font-weight: 350;    
                        color: rgb(221, 220, 220);
                    }
                    &-message {
                        font-size: $font-size-13;
                        font-weight: 350;    
                        color: $grey--four;
                    }
                }
                .unread {
                    &-title {
                        font-size: $font-size-18;
                        font-weight: 500;
                        color: white;
                        }
                    &-message {
                        font-size: $font-size-15;
                        font-weight: 500;    
                        color: white;
                    }                
                }
            }
        }

        .right-view {
            display: flex;
            align-items: center;
            flex-direction: row;

            .header-button {
                width: 40px;
                height: 40px;
                img {
                    width: 20px;
                    height: 20px;
                }
            }

            .info {
                display: flex;
                align-items: flex-end;
                flex-direction: column;
                justify-content: space-around;
                width: 100px;
                .date {
                    font-size: $font-size-13;
                    line-height: 1.3;
                    color: $grey--four;
                }
                .pinned {
                    width: 10px;
                    height: 10px;
                    object-fit: cover;
                    border: none;
                }
            }
        }
    }
}

.vault {
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: 0;
}

.full-view-width {
    width: 96%;
    margin-left: 2%;
}

.play-cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 10px;

    button {
        display: flex;
        align-items: center;
        justify-content: center;

        width: auto;
        height: 40px;
        min-width: 40%;
        min-height: 40px;
        border: solid 1px gray;
        border-radius: 20px;

        label {
            font-size: $font-size-16;
            font-weight: 400;
            color: white;
            margin-left: 5px;
        }

        img {
            width: 15px;
            height: 15px;
            object-fit: contain;
            margin-right: 5px;
        }
    }

    .edit {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        min-width: 40px;
        min-height: 40px;
        border: none;
        img {
            width: 15px;
            height: 15px;
            object-fit: contain;
        }
    }
}

.panel-on-bottom {
    margin-top: 1rem;
    padding: 1rem;
    background-color: black;
    position: fixed;
    bottom: 85px;
    left: 0;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 50%;
        max-width: 400px;
        @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
            width: 100%;
        }
        height: 40px;
        min-width: 45%;
        min-height: 40px;
        border: solid 1px gray;
        border-radius: 20px;

        label {
            font-size: $font-size-16;
            font-weight: 400;
            color: white;
            margin-left: 5px;
        }

        img {
            width: 15px;
            height: 15px;
            object-fit: contain;
            margin-right: 5px;
        }
    }
}

.bottom-165 {
    bottom: 165px;
}
