.upload-music {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    background-color: transparent;

    .upload-music-header {
        width: 100%;
        padding: 20px 20px;
        background-color: $primary-background-color;
        display: flex;
        align-items: center;
        flex-direction: center;
        justify-content: space-between;

        .save-button {
            width: 100px;
            height: 44px;
            min-width: 30px;
            min-height: 30px;
            background-color: transparent;
            border-radius: 22px;
            border-width: 1px;
            border-style: solid;
            border-color: $primary-button-background-color;

            font-size: $font-size-13;
            font-weight: 450;
            line-height: 1;
            padding: 10px 0;
            color: $primary-button-background-color;
        }

        .title {
            width: 90%;
            padding-right: 30px;
            text-align: center;
            align-self: center;
            font-size: $font-size-16;
            color: white;
        }
    }

    .upload-music-form {
        width: 100%;
        display: flex;
        flex-direction:column;
        align-content:space-between;
        align-items: center;
        background-color: $primary-background-color;
        &__single-field {
            label {
                font-size: $font-size-14;

                margin-bottom: 20px;

                color: grey;
            }
            input,
            textarea {
                font-size: $font-size-14;

                display: block;
                background-color: $grey--six;
                height: 44px;
                width: 100%;
                padding: 10px;

                color: $white;
                border: none;
            }

            ::placeholder {
                opacity: 1; /* Firefox */

                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $grey--four;
                font-size: $font-size-12;
            }

            :-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: $grey--four;
            }

            ::-ms-input-placeholder {
                /* Microsoft Edge */
                color: $grey--four;
            }

            .check-container {
                display: flex;
                align-items: center;
                flex-direction: center;
                justify-content: space-between;

                .container {
                    display: block;
                    position: relative;
                    padding-left: 28px;
                    margin-bottom: 12px;
                    cursor: pointer;
                    font-size: $font-size-13;
                    font-weight: 350;
                    color: white;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                }

                /* Hide the browser's default checkbox */
                .container input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                }

                /* Create a custom checkbox */
                .checkmark {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 20px;
                    width: 20px;
                    border: 1px solid grey;
                    border-radius: 3px;
                }

                /* On mouse-over, add a grey background color */
                //   .container:hover input ~ .checkmark {
                //     background-color: #ccc;
                //   }

                /* When the checkbox is checked, add a blue background */
                .container input:checked ~ .checkmark {
                    background-color: $primary-button-background-color;
                    border: 1px solid $primary-button-background-color;
                }

                /* Create the checkmark/indicator (hidden when not checked) */
                .checkmark:after {
                    content: "";
                    position: absolute;
                    display: none;
                }

                /* Show the checkmark when checked */
                .container input:checked ~ .checkmark:after {
                    display: block;
                }

                /* Style the checkmark/indicator */
                .container .checkmark:after {
                    left: 6.5px;
                    top: 3.5px;
                    width: 5px;
                    height: 10px;
                    border: solid white;
                    border-width: 0 3px 3px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }
        }

        &__button {
            font-size: $font-size-16;
            line-height: 1;

            width: 100%;
            padding: 15px 0;

            color: $white;
            border: none;
            background: none;
            background-color: $theme-color--default;
        }

        
        .upload-now {
            display: flex;
            align-items: center;
            .upload-button {
                font-size: $font-size-13;
                line-height: 1;
                text-align: center;
                display: block;
                min-width: 80px;
                width: 100%;
                height: 36px;
                color: $white;
                border-radius: 20px;
                background-color: $primary-button-background-color;
                border: none;
            }

            .upload-button-disabled {
                font-size: $font-size-13;
                line-height: 1;
                text-align: center;
                display: block;
                min-width: 80px;
                width: 100%;
                height: 36px;
                color: $grey--eight;
                border-radius: 20px;
                background-color: $primary-button-disabled-color;
                border: none;
            }
        }
    }
}
