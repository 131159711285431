/* auth page header */

.auth-page-header {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;

    padding: 30px 0;

    &__title {
        font-size: $font-size-13;
        text-align: center;

        color: $grey;
        margin-bottom: 50px;
    }

    &__text {
        color: $grey--four;
    }

    &__logoUS {
        margin-left: 180px;
        margin-right: 180px;
        margin-bottom: 20px;
    }

    &__textUS {
        margin-left: 100px;
        margin-right: 100px;
        margin-bottom: 20px;
    }
}

/* auth page body */

.auth-page-body {
    .auth-form {
        padding: 40px 8px;
        background-color: $primary-background-color;

        &__single-field {
            label {
                font-size: $font-size-14;
                font-weight: bold;
                margin-bottom: 10px;
                color: $grey--nineteen;
            }
            input,
            textarea {
                font-size: $font-size-14;

                display: block;
                background-color: $grey--six;
                height: 44px;
                width: 100%;
                padding: 10px;

                color: $white;
                border: none;
                // border-bottom: 2px solid $grey--eight;

                // &:focus {
                //     border-bottom: 2px solid $grey;
                // }
            }

            ::placeholder {
                opacity: 1; /* Firefox */

                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $grey--four;
            }

            :-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: $grey--four;
            }

            ::-ms-input-placeholder {
                /* Microsoft Edge */
                color: $grey--four;
            }
        }

        &__remember-forgot {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;

            .forgot {
                color: white;
                font-size: $font-size-12;
                font-weight: bold;
                text-align: right;
            }
        }

        &__info-text {
            color: white;
            border: none;
            background: none;
            font-size: $font-size-12;
            font-weight: bold;
            text-align: right;
            float: right;
        }

        &__signin-btn {
            font-size: $font-size-14;
            font-weight: bold;
            line-height: 1;
            text-align: center;
            display: block;

            width: 100vw;
            height: 50px;
            margin-left: 15px;
            margin-right: 15px;
            // margin-bottom: 40px;
            padding: 18px;

            color: $white;
            border-radius: 15px;
            background-color: $primary-button-background-color;
        }

        &__signup-btn {
            font-size: $font-size-14;
            font-weight: bold;
            line-height: 1;
            text-align: center;
            display: block;

            width: 38vw;
            height: 44px;
            margin: 0 auto;
            margin-bottom: 40px;
            padding: 18px;
            margin-right: 10px;

            color: $white;
            border-radius: 25px;
            background-color: $black;

            border-width: 1px;
            border-style: solid;
            border-color: white;
        }

        .container {
            display: block;
            position: relative;
            margin-bottom: 12px;
            cursor: pointer;
            font-size: $font-size-13;
            font-weight: 350;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        /* Hide the browser's default checkbox */
        .container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        /* Create a custom checkbox */
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            border: 1px solid grey;
            border-radius: 3px;
        }

        /* On mouse-over, add a grey background color */
        //   .container:hover input ~ .checkmark {
        //     background-color: #ccc;
        //   }

        /* When the checkbox is checked, add a blue background */
        .container input:checked ~ .checkmark {
            background-color: $primary-button-background-color;
            border: 1px solid $primary-button-background-color;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }

        /* Show the checkmark when checked */
        .container input:checked ~ .checkmark:after {
            display: block;
        }

        /* Style the checkmark/indicator */
        .container .checkmark:after {
            left: 6.5px;
            top: 3.5px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}

/* auth page footer */

.auth-page-footer {
    &__container {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;
    }

    &__term-text {
        font-size: $font-size-12;
        color: $grey;
        text-align: right;
        padding-right: 2px;
    }

    &__term-btn {
        color: $white;
        border: none;
        background: none;
        font-size: $font-size-12;
        font-weight: bold;
        float: left;
        padding-left: 2px;
    }

    &__social-btn {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;

        width: 25vw;
        height: 65px;

        border-radius: 5px;
        background-color: transparent;
        border: solid 1px $grey--nineteen;
        margin-left: 15px;
        margin-right: 15px;

        .icon {
            display: block;
            max-width: 30px;
            max-height: 30px;
            width: auto;
            height: auto;
        }
    }
}

.auth-page-separator {
    font-size: $font-size-18;
    line-height: 1;

    display: block;

    color: $black--two;
}

.auth-page-social-login {
    button {
        line-height: 1;

        position: relative;

        display: block;

        width: 100%;
        margin-bottom: 20px;
        padding: 15px 0;

        color: $black;
        border: 1px solid $grey--seventeen;
        border-radius: 5px;
        background-color: transparent;
        &:last-child {
            margin-bottom: 0;
        }

        svg,
        img {
            position: absolute;
            top: 50%;
            left: 30px;

            transform: translateY(-50%);
        }
    }
}

.forgot-form-notice {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
}

.forgot-form-notice-label {
    font-size: $font-size-12;
    color: $grey--four;
    text-align: center;
}

.forgot-form-notice-label1 {
    font-size: $font-size-10;
    color: $grey--four;
    text-align: center;
}