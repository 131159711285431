.wallet-section {
    width: 100%;

    .title {
        font-size: $font-size-13;
        line-height: 1.3;
        color: white;
        margin-left: 20px;
    }

    .account-balance {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin-top: 20px;

        .amount {
            height: 0px;
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: baseline;

            img {
                width: 25px;
                object-fit: contain;
                margin-right: 10px;
                margin-bottom: 5px;
            }

            label {
                font-size: $font-size-18;
                font-weight: 350;
                color: white;
            }
        }

        .buttons {
            width: 100%;
            display: inline-block;
            position: relative;
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-evenly;
            margin-top: 40px;
            button {
                font-size: $font-size-13;
                font-weight: 450;
                line-height: 1;
                text-align: center;
                display: block;
                width: 35%;
                height: 40px;
                float: right;
                color: $white;
                border-radius: 20px;
                border: none;
            }

            .pink {
                background-color: $primary-button-background-color;
            }

            .green {
                background-color: $forgot-button-background-color;
            }
        }

        .history {
            margin-top: 40px;
            width: 80%;
            height: 40px;
            border-radius: 5px;
            border: 1px solid grey;

            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;

            label {
                font-size: $font-size-13;
                font-weight: 450;
                line-height: 1;
                text-align: left;  
                align-items: center;
                display: flex;

                color: $white;
                margin-top: 6px;
                margin-left: 10px;
            }
            
            img {
                align-self: right;
                width: 15px;
                height: 15px;
                object-fit: contain;
                margin-right: 10px;
            }
        }
    }
}

.transaction {
    width: 100%;
    display: flex;
    align-items: left;
    flex-direction: row;
    justify-content: space-between;
    background-color: transparent;
    margin-bottom: 1px;

    &__content {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .content {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 10px;
            margin-left: 20px;
            margin-bottom: 10px;
            margin-right: 15px;
            width: 100%;

            .left-view {
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: space-around;

                img {
                    margin-top: 5px;
                    // margin-left: 10px;
                    margin-bottom: 5px;
                    margin-right: 10px;

                    width: 40px;
                    height: 40px;
                    min-width: 40px;
                    min-height: 40px;
                    border-radius: 40px;
                    object-fit: cover;
                    margin-right: 10px;
                    border-width: 1px;
                    border-style: solid;
                    border-color: #5e5964;
                }

                .info {
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    justify-content: space-around;

                    .name {
                        font-size: $font-size-13;
                        line-height: 1.3;
                        color: white;
                        margin-top: 3px;
                        margin-bottom: 3px;
                    }

                    .time-stamp {
                        font-size: $font-size-11;
                        line-height: 1;
                        text-align: left;
                        display: inline-block;
                        color: $grey--four;
                        margin-top: 3px;
                        margin-bottom: 3px;
                    }
                }
            }

            .right-view {
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: center;

                .amount {
                    font-size: $font-size-11;
                    line-height: 1;
                    text-align: right;
                    display: inline-block;
                    color: greenyellow;
                }

                .negative {
                    color: red;
                }

                img {
                    align-self: right;
                    width: 15px;
                    height: 15px;
                    object-fit: contain;
                    margin-left: 10px;
                }
            }
        }
    }

    img {
        margin: 0 auto;
    }
}

.transaction-details {
    display: flex;
    align-items: left;
    flex-direction: column;
    justify-content: baseline;
    width: 100%;

    &-title {
        width: 100%;
        font-size: $font-size-13;
        line-height: 1.3;
        font-weight: 300;
        color: white;
    }

    &-value {
        width: 100%;
        font-size: $font-size-13;
        line-height: 1.3;
        font-weight: 500;
        color: white;
        margin-top: 0px;
    }

    &-reference-title {
        width: 100%;
        font-size: $font-size-11;
        line-height: 1.3;
        font-weight: 300;
        color: gray;
    }

    &-reference-value {
        font-size: $font-size-11;
        font-weight: 500;
        color: gray;
        margin-top: 0px;
    }

    .minus {
        color: red;
    }

}
