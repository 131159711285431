/* Modal Content/Box */
.modal-content {
  background-color: black;
  width: 100%; /* Could be more or less, depending on screen size */
  height: 100%;

  .title-wrap {
    position: relative;
    &::before {
      position: absolute;
      z-index: 1;
      top: 10px;
      left: 0;

      width: 100%;
      height: 1px;

      content: "";
      transition: all 0.4s ease 0s;
    }
    h4.cart-bottom-title {
      font-size: $font-size-18;
      font-weight: 500;
      color: white;
      display: inline-block;

      margin: 0;
      padding-right: 18px;
    }
  }

  .discount-code {
    margin: 21px 0 0;
    p {
      margin: 0 0 15px;
    }
    form {
      input {
        width: 100%;
        height: 40px;
        margin-bottom: 30px;
        padding-left: 10px;

        border: 1px solid $grey--seven;
      }
      button.cart-btn-1 {
        font-size: $font-size-10;
        line-height: 1;
        text-align: center;
        display: block;
        width: 80px;
        height: 30px;
        float: right;
        margin-right: 10px;
        margin-top: 0px;
        color: $black--five;
        border-radius: 15px;
        background-color: whitesmoke;
      }

      button.cart-btn-2 {
        font-size: $font-size-10;
        line-height: 1;
        text-align: center;
        display: block;
        width: 80px;
        height: 30px;
        float: right;
        margin-left: 10px;
        margin-top: 0px;
        color: $white;
        border-radius: 15px;
        background-color: $primary-button-background-color;
      }

      .slider-rating {
        background-color: transparent;
        height: 3px;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 40px;
        border-radius: 1.5px;
        color: white;
        background-color: $primary-button-background-color;
        // border: 3px solid $primary-button-background-color;

        img {
          width: 20px;
          height: 25px;
          margin-bottom: 5px;
        }

        .example-track {
          position: relative;
          background: transparent;
        }
        .example-track.example-track-1 {
          background: #f00;
        }
        .example-track.example-track-2 {
          background: #0f0;
        }

        .example-mark {
          position: absolute;
          width: 8px;
          height: 8px;
          border: 2px solid #000;
          background-color: #fff;
          cursor: pointer;
          border-radius: 50%;
          vertical-align: middle;
        }

        .example-thumb {
          font-size: 1em;
          text-align: center;
          background-color: transparent;
          color: white;
          width: 20px;
          height: 50px;
          margin-top: -25px;
        }

        .example-thumb.active {
          background-color: transparent;
        }
      }
    }
  }
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.title-label {
  width: 60vw;
  padding-left: 30px;
  text-align: center;
  font-size: $font-size-15;
  color: white;
}

// .container-sticky {
//   display: flex;
//   justify-content: space-around;
//   align-items: flex-start;
//   border: 2px dashed rgba(114, 186, 94, 0.35);
//   height: 400px;
//   background: rgba(114, 186, 94, 0.05);
// }

.item-sticky {
  position: sticky;
  z-index: 9999;
  background-color: black;
  top: 0;
}

.checked-mark {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translateY(-40%);
  transform: translateX(-50%);
  border-color: transparent;
}

.margin-top-minus-40 {
  margin-top: -20px;
}
