/*=============================================
=            notification            =
=============================================*/

/* notification header */

.notifications {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;

    .cell {
        width: 100%;
        display: flex;
        align-items: left;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 1px;
        &__content {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: space-around;
            width: 100%;
            .content {
                display: flex;
                align-items: center;
                flex-direction: row;
                // justify-content: space-around;
                margin-top: 10px;
                margin-left: 20px;
                margin-bottom: 10px;
                margin-right: 20px;
                .image {
                    margin-top: 10px;
                    margin-left: 10px;
                    margin-bottom: 5px;
                    margin-right: 10px;

                    .thumbnail {
                        width: 40px;
                        height: 40px;
                        min-width: 40px;
                        min-height: 40px;
                        border-radius: 40px;
                        object-fit: cover;
                        margin-right: 10px;
                        border-width: 1px;
                        border-style: solid;
                        border-color: #5e5964;
                    }
                }

                .verb {
                    font-size: $font-size-13;
                    line-height: 1.3;
                    color: white;
                }
            }
            .time-stamp {
                font-size: $font-size-11;
                line-height: 1;
                text-align: right;
                margin-bottom: 10px;
                margin-right: 20px;
                align-self: flex-end;
                color: $grey--four;
            }
        }
    }
    .background-unread {
        background-color: #28252b;
    }
}

/*=====  End of notification  ======*/
