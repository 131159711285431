/* 3rd party styles */
@import "~bootstrap/scss/bootstrap";
@import "~swiper/css/swiper.css";
@import "~animate.css/animate.min.css";

/* project styles */

@import "1-base/variables";
@import "2-global/default";
@import "2-global/common";
@import "2-global/helper";
@import "2-global/spacing";
@import "3-layouts/header";
@import "3-layouts/footer";
@import "4-elements/button";
@import "4-elements/label";
@import "4-elements/section-title";
@import "4-elements/backgrounds";
@import "4-elements/breadcrumb";
@import "4-elements/preloader";
@import "4-elements/no-items-found";
@import "5-sections/hero-slider";
@import "5-sections/category-slider";
@import "5-sections/product";
@import "5-sections/contact-content";
@import "5-sections/profile";
@import "5-sections/postdetail";
@import "5-sections/playlistdetail";
@import "5-sections/playlist";
@import "5-sections/modal";
@import "5-sections/feed";
@import "5-sections/auth";
@import "5-sections/welcome";
@import "5-sections/notification";
@import "5-sections/chat";
@import "5-sections/cart";
@import "5-sections/checkout";
@import "5-sections/support";
@import "5-sections/wallet";
@import "5-sections/upload-music";
@import "5-sections/cashout";
@import "5-sections/friendlist";
@import "5-sections/common";
@import "5-sections/search_field";
@import "5-sections/cell";
@import "5-sections/skills";

.react-responsive-modal-containerCenter:after {
    height: auto;
}

.simple-page-scroller{
    margin: 0 auto;
    overflow: auto;
    height: 99vh;
}



