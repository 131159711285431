.bg-color--grey {
    background-color: rgba($grey, 0.10);
}
.bg-color--grey2 {
    background-color: #F8F8F8;
}

.bg-color--gradient {
    background-image: linear-gradient(to bottom,  #FFFFFF 0%,#F1F1F1 100%);
}

.bg-color--primary {
    background-color: rgba($black, 1.0);
}