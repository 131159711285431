/*=============================================
=            Header            =
=============================================*/
header {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $primary-background-color;
    border-bottom: 1px solid $grey--six;

    .header-container {
        .header-logo {
            background-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            .logo {
                height: 20px;
                object-fit: contain;
            }
            .rightbutton {
                height: 20px;
                object-fit: contain;
                align-self: flex-end;
                position: absolute;
                right: 0;
                bottom: 2px;
                cursor: pointer;
            }
        }

        .header-conversation {
            position: relative;
            .left-view {
                // display: flex;
                // flex-direction: row;
                // align-items: center;
                // justify-content: flex-start;
                // align-self: flex-start;
                position: relative;

                .left-view-content {
                    margin-left: 20px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    align-self: flex-start;
                    .leftbutton {
                        height: 20px;
                        object-fit: contain;
                        align-self: center;
                        img {
                            height: 12px;
                        }
                    }

                    .avatar {
                        width: 40px;
                        height: 40px;
                        min-width: 40px;
                        min-height: 40px;
                        margin-left: 10px;
                        margin-right: 10px;
                        object-fit: cover;
                        border-radius: 20px;
                        border: solid 1px gray;
                        align-self: center;
                    }

                    .header-title {
                        align-self: center;
                        color: white;
                        font-size: $font-size-16;
                        font-weight: 450;
                        text-align: left;
                    }
                }
            }

            .rightbutton {
                width: 40px;
                height: 40px;
                align-items: center;
                position: relative;
                right: 0;
                top: 0px;
                margin-right: 10px;
                cursor: pointer;
                background-color: gold;
                border: none;
            }
        }
    }

    .header-with-leftbutton {
        height: 60px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .header-title {
            margin-top: 10px;
            float: center;
            width: 100%;
            text-align: center;
            color: white;
            font-size: $font-size-16;
            font-weight: 450;
        }

        .left-button {
            display: flex;
            padding-right: 40px;
            background-color: transparent;
            border: solid transparent;
            .icon {
                background-color: transparent;
                height: 5px;
                object-fit: contain;
                margin-left: 40px;
            }
        }
    }

    .header-container-notifications {
        height: 60px;
        width: 100%;
        display: flex;
        justify-content: center; /* adjustment */
        align-items: center;
        position: relative; /* new */

        &.back-enable {
            justify-content: space-between;
        }

        .header-title {
            width: 200px;
            position: absolute; /* new */
            left: 50%;
            transform: translateX(-50%);
        }

        .more-button {
            display: flex;
            background-color: transparent;
            border: solid transparent;
            .icon {
                background-color: transparent;
                height: 5px;
                object-fit: contain;
                margin-right: 0px;
            }
        }
    }

    .header-search {
        max-width: 230px;
        form {
            position: relative;
            input {
                font-size: $font-size-14;

                width: 100%;
                padding: 7px 30px 7px 40px;

                color: #666666;
                border: none;
                border-radius: 25px;
                background-color: #efefef;
            }
            svg,
            img {
                position: absolute;
                top: 50%;
                left: 15px;

                transform: translateY(-50%);
            }
        }
    }

    .header-menu-trigger {
        padding: 0;

        border: none;
        background: none;
        &:focus {
            outline: none;
        }
    }
}

.header-container {
    background-color: transparent;
    height: 60px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.header-button {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    z-index: 0;
    img {
        background-color: transparent;
        object-fit: contain;
        width: 20px;
        height: 20px;
    }
    label {
        font-family: $body-font-family;
        font-size: $font-size-14;
        font-weight: 450;
        color: white;
        text-align: center;
        width: 100%;
        margin-top: 3px;
    }
}

.header-title {
    font-family: $body-font-family;
    font-size: $font-size-18;
    font-weight: 450;
    color: white;
    text-align: center;
    width: 100%;
    margin-top: 3px;
}

.section-header-title {
    font-family: $body-font-family;
    font-size: $font-size-16;
    font-weight: 350;
    color: gray;
    text-align: left;
    width: 100%;
    margin-top: 3px;
}

/* offcanvas menu */

.profile-card {
    &__image {
        img {
            border-radius: 50%;
        }
    }
    &__content {
        p {
            font-weight: 500;

            color: $black--four;
            span {
                font-size: $font-size-12;
                font-weight: 400;

                display: block;
            }
        }
    }
}

.offcanvas-menu {
    position: fixed;
    z-index: 9999;
    top: 70px;
    right: 0;

    visibility: hidden;

    width: 280px;
    max-width: 100%;
    height: 100vh;
    margin-left: auto;
    padding: 40px;

    transition: $transition--medium;
    transform: translateX(100%);

    background-color: #f1f1f1;
    background-image: linear-gradient(to bottom, #f1f1f1 0%, #f2f8ff 100%);
    box-shadow: -5px 0 15px rgba($black, 0.16);

    &.active {
        visibility: visible;

        transform: translateX(0);
    }
}

.offcanvas-navigation {
    overflow-y: scroll;

    height: 400px;
    margin: 0 -40px;
    padding: 0 40px;

    li {
        display: flex;
        align-items: center;

        padding: 15px 10px 15px 30px;

        border-bottom: 2px solid $white;

        &:nth-child(1) {
            border-top: 2px solid $white;
        }

        span {
            display: block;

            margin-right: 15px;

            svg,
            img {
                width: 14px;
                height: 14px;
            }
        }

        a {
            display: block;

            width: 100%;

            color: $black--four;
        }
    }
}

/* search keyword */

.search-keyword-area {
    display: none;
    &.active {
        display: block;
    }
}

.search-keyword-header {
    line-height: 1;

    display: flex;
    align-items: center;
    justify-content: space-between;
    &__title {
        font-size: $font-size-14;

        flex-basis: 80%;
    }

    &__gl-switcher {
        flex-basis: 20%;
        button {
            padding: 0;

            border: none;
            background: none;
        }
    }
}

.search-keywords {
    margin-bottom: -10px;
    li {
        display: inline-block;

        margin-right: 10px;
        margin-bottom: 10px;
        a {
            font-size: $font-size-13;
            line-height: 1.3;

            display: block;

            padding: 5px 15px;

            color: $black--five;
            border: 1px solid $grey--five;
            background-color: $white;
        }
    }
}

/*=====  End of Header  ======*/
