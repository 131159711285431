
/*=============================================
=            Label            =
=============================================*/

.text-alignment {
    &--right {
        text-align: right;
    }

    &--left {
        text-align: left;
    }

    &--center {
        text-align: center;
    }
}


/*=====  End of Label  ======*/

