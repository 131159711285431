
/*=============================================
=            welcome area            =
=============================================*/

.welcome-slider-wrapper {
    background-color: black;
    position: relative;
    .logo {
        img {
            margin: 0 auto;
        }
    }
    .title {
        font-size: $font-size-14;
        font-weight: 500;
    }
    .descriptionText {
        font-size: $font-size-17;
        font-weight: bold;
        color: $grey--two;
        margin-bottom: 40px;
    }

    .welcome-logo {
        // width: 105px;
        margin-left: 150px;
        margin-right: 150px;
        margin-bottom: 20px;
    }

    .welcome-text {
        // width: 220px;
        margin-left: 100px;
        margin-right: 100px;
    }

    .musician-btn {
        font-size: $font-size-14;
        font-weight: bold;
        line-height: 1;

        display: block;

        width: 160px;
        height: 50px;
        margin: 0 auto;
        margin-bottom: 40px;
        padding: 18px;
        margin-right: 10px;

        color: $white;
        border-radius: 25px;
        background-color: $primary-button-background-color;
    }

    .listener-btn {
        font-size: $font-size-14;
        font-weight: bold;
        line-height: 1;

        display: block;

        width: 160px;
        height: 50px;
        margin: 0 auto;
        margin-bottom: 40px;
        padding: 18px;
        margin-left: 10px;

        color: $white;
        border-radius: 25px;
        background-color: $black;

        border-width: 1px;
        border-style: solid;
        border-color: white;
    }

    .signin-text {
        font-size: $font-size-12;
        color: $grey;
        text-align: right;
        padding-right: 0px;
        text-align: center;
    }

    .signin-btn {
        color: $white;
        border: none;
        background: none;
        font-size: $font-size-12;
        font-weight: bold;
        // float: left;  
        padding-left: 0px;      
    }

    .slick-dots {
        line-height: 1;

        position: absolute;
        bottom: 20px;
        left: 50%;

        transform: translateX(-50%);
        li {
            display: inline-block;

            margin-right: 10px;
            &:last-child {
                margin-right: 0;
            }
            &.slick-active {
                button {
                    background-color: $red--two;
                }
            }
            button {
                font-size: 0;

                width: 8px;
                height: 8px;
                padding: 0;

                border: none;
                border-radius: 50%;
                background: none;
                background-color: $grey--four;
            }
        }
    }
}

.single-welcome-slide {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    
    height: 100vh;
    padding: 30px 0;
}

/*=====  End of welcome area  ======*/

