/*=============================================
=            feeds            =
=============================================*/

/* feeds header */

.feeds {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;

    &__empty-button {
        display: block;
        width: 80px;
        height: 30px;
    }
    &__pink-button {
        font-size: $font-size-10;
        line-height: 1;
        text-align: center;
        display: block;
        width: 80px;
        min-width: 80px;
        height: 30px;
        float: right;
        color: $white;
        border-radius: 15px;
        background-color: $primary-button-background-color;
        border: none;
    }

    &__gray-button {
        font-size: $font-size-10;
        line-height: 1;
        text-align: center;
        display: block;
        width: 80px;
        min-width: 80px;
        height: 30px;
        float: right;
        color: $white;
        border-radius: 15px;
        background-color: gray;
        border: none;
    }

    &__green-button {
        font-size: $font-size-10;
        line-height: 1;
        text-align: center;
        display: block;
        width: 80px;
        height: 30px;
        float: right;
        color: $white;
        border-radius: 15px;
        background-color: $forgot-button-background-color;
        border: none;
    }

    &__small-text {
        font-size: $font-size-12;
        line-height: 1.2;
        color: white;
        margin-top: 10px;
    }

    .empty-text {
        font-size: $font-size-16;
        line-height: 1.2;
        color: $grey--two;
        margin-top: 100px;
    }

    .cell {
        width: 100%;
        display: flex;
        align-items: left;
        flex-direction: column;
        justify-content: space-between;
        background-color: transparent;
        margin-bottom: 1px;

        .header {
            width: 100%;
            display: flex;
            align-items: left;
            flex-direction: row;
            justify-content: start;
            background-color: transparent;
            margin-bottom: 1px;
            // margin-left: 20px;
            .thumbnail {
                width: 40px;
                height: 40px;
                min-width: 40px;
                min-height: 40px;
                border-radius: 40px;
                object-fit: cover;
                margin-left: 10px;
                margin-right: 10px;
                border-width: 1px;
                border-style: solid;
                border-color: #5e5964;
                transform: translateY(5px);
            }
            .name-area {
                display: flex;
                max-width: 50%;
                align-items: flex-start;
                flex-direction: column;
                justify-content: space-between;
                background-color: transparent;
                .username {
                    font-size: $font-size-13;
                    line-height: 1.3;
                    color: white;
                }

                .songname {
                    font-size: $font-size-13;
                    line-height: 1.3;
                    color: white;
                }
            }

            .button-area {
                display: flex;
                flex: 1 1 auto;
                align-items: center;
                flex-direction: row;
                justify-content: flex-end;
                padding-right: 15px;
                background-color: transparent;

                .header-button {
                    margin-right: -10px;
                }
            }
        }

        .content {
            overflow: hidden;
            border-radius: 8px;
            width: calc(100% - 20px);
            background-color: transparent;
            margin-top: 5px;
            .box-ratio {
                height: 0;
                overflow: hidden;
                padding-top: 100%;
                background: transparent;
                position: relative;
                .main-image {
                    position: absolute;
                    top: 0%;
                    left: 0%;
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                    object-fit: cover;
                    border-width: 1px;
                    border-style: solid;
                    border-color: #5e59646c;
                }
            }
        }

        .footer {
            width: 100%;
            display: flex;
            align-items: left;
            flex-direction: column;
            justify-content: space-between;
            background-color: transparent;
            margin-bottom: 1px;
            padding: 20px;

            margin-top: -70px;
            z-index: 10;

            .info-area {
                display: flex;
                align-items: left;
                flex-direction: row;
                justify-content: flex-end;
                background-color: transparent;
                float: right;
                .rounded-background {
                    display: flex;
                    height: 35px;
                    width: auto;
                    min-width: 35px;
                    flex-direction: row;
                    justify-content: center;
                    padding-left: 0px;
                    padding-top: 0px;
                    margin-left: 10px;
                    float: right;
                    align-items: center;
                    border-radius: 18px;
                    background-color: rgba($color: #000, $alpha: 0.5);
                    .firegif {
                        width: 20px;
                        height: 20px;
                        margin-left: 5px;
                        transform: translateY(-20%);
                    }
                    .rating {
                        font-size: $font-size-13;
                        font-weight: 600;
                        line-height: 1.5;
                        color: white;
                        margin-right: 10px;
                    }
                }
            }

            .description {
                margin-top: 20px;
                font-size: $font-size-13;
                line-height: 1.3;
                color: white;
            }

            .review-timestamp {
                display: flex;
                align-items: flex-end;
                flex-direction: row;
                justify-content: space-between;
                background-color: transparent;
                float: right;

                .review {
                    display: flex;
                    align-items: flex-end;
                    flex-direction: column;
                    justify-content: space-between;
                    background-color: transparent;
                    float: right;
                    margin-top: 15px;
                }
            }
        }
    }
}

.tags {
    font-size: $font-size-13;
    line-height: 1.3;
    color: white;
    white-space: nowrap;
}

.bottom-seperator {
    border-top: 1px solid #60606060;
    margin-bottom: 20px;
}

.shared-feeds {
    width: 100%;
    .container {
        height: 100px;
        width: 100%;
        overflow: hidden !important;
        overflow-x: scroll !important;

        .tiles {
            padding: 0px;
            margin: 0px;
            height: 84px;
            display: flex;
            width: 100%;
            justify-content: space-between;

            .row {
                display: flex;
                width: 80px;
                margin: 2px 2px;
                align-items: center;
                justify-content: center;
                color: white;
                font-family: sans-serif;
                font-weight: bold;
                font-size: 24px;
                cursor: grab;
                height: 80px;
                flex: 1;
            }
        }
    }

    .container::-webkit-scrollbar {
        width: 8px;
        background-color: #ddd;
    }

    /**  STYLE 11 */
    .container::-webkit-scrollbar-track {
        border-radius: 8px;
        background: #ddd;
    }

    .container::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background: #999;
    }

    .container::-webkit-scrollbar-thumb:hover {
        background: #666;
    }

    .container::-webkit-scrollbar-thumb:active {
        background: #333;
    }
}

/*=====  End of feeds  ======*/
