/*=============================================
=            playlistdetail            =
=============================================*/

/* playlistdetail header */

.playlistdetail-header {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    &__image {
        display: flex;
        align-items: bottom;
        justify-content: space-around;

        img {
            width: 150px;
            height: 150px;
            border-radius: 8px;
            flex-shrink: 0;
            object-fit: cover;
        }

        button {
            width: 40px;
            height: 40px;
            border-top-left-radius: 8px;
            border: none;
            margin-top: 110px;
            margin-left: -40px;
            background-color: rgba($color: #000000, $alpha: 0.5);
            margin-bottom: 0px;
            img {
                width: 20px;
                height: 20px;
                flex-shrink: 0;
                object-fit: contain;
            }
        }
    }

    &__profile-container {
        width: 100%;
        margin-top: 20px;
        align-items: left;
        flex-direction: column;
        justify-content: left;

        .profile {
            display: block;
            align-items: left;
            flex-direction: column;
            justify-content: left;
            width: 100%;
            img {
                width: 24px;
                height: 24px;
                min-width: 24px;
                min-height: 24px;
                border-radius: 12px;
                background-color: gray;
            }

            .song-name {
                font-size: $font-size-16;
                font-weight: 500;
                color: white;
            }

            .author-name {
                font-size: $font-size-14;
                font-weight: 400;
                color: white;
            }

            .heart-container {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-direction: row;
                button {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;

                    width: 40px;
                    height: 40px;
                    min-width: 40px;
                    min-height: 40px;
                    background-color: transparent;
                    border: none;
                    margin-left: -12px;

                    .heart {
                        width: 12px;
                        height: 12px;
                        min-width: 12px;
                        min-height: 12px;
                        object-fit: contain;
                        background-color: transparent;
                    }
                }

                label {
                    font-size: $font-size-14;
                    font-weight: 400;
                    color: $grey--four;
                }
            }

            .tag-container {
                display: block;
                align-items: center;
                justify-content: flex-start;
                flex-direction: row;

                label {                    
                    font-size: $font-size-14;
                    font-weight: 400;
                    color: $grey--four;
                    margin-right: 5px;
                }
            }

            .share-button {
                position: relative;
                cursor: pointer;
                float: right;
                width: 40px;
                height: 40px;

                display: flex;
                align-items: center;
                justify-content: center;
            }   
        }
    }

    .playlistdetail-info {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;

        &-block {
            &__username {
                font-size: $font-size-12;
                font-weight: 700;
                color: $white;
                text-align: center;
                margin-bottom: 5px;
            }
            &__usertype {
                font-size: $font-size-10;
                line-height: 1;
                color: $grey;
                text-align: center;
                margin-bottom: 15px;
            }
            &__usertag {
                font-size: $font-size-10;
                line-height: 1;
                color: $grey;
                text-align: center;
                margin-bottom: 15px;
            }
        }
    }

    .playlistdetail-connection {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;

        &__region {
            font-size: $font-size-12;
            color: $grey;
        }

        &__separator {
            width: 1px;
            height: 20px;
            background-color: $grey;
            margin-left: 10px;
        }

        &__icon {
            width: 20px;
            height: 20px;
            border-radius: 10px;
            margin-left: 10px;
            margin-right: 3px;
        }

        &__number {
            font-size: $font-size-12;
            color: $grey;
        }

        &__btn {
            font-size: $font-size-14;
            font-weight: bold;
            line-height: 1;
            text-align: center;
            display: block;

            width: 100vw;
            height: 50px;
            margin-left: 15px;
            margin-right: 15px;
            // margin-bottom: 40px;
            padding: 18px;

            color: $white;
            border-radius: 15px;
            background-color: $primary-button-background-color;
        }
    }

    .playlistdetail-buttons {
        margin-top: 20px;

        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;

        &__social {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-around;

            width: 50px;
            height: 50px;

            border-radius: 15px;
            background-color: $grey--nineteen;
            border-color: $grey--nineteen;
            margin-left: 5px;
            margin-right: 5px;

            .icon {
                display: block;
                max-width: 30px;
                max-height: 30px;
                width: auto;
                height: auto;
            }
        }

        &__primary {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-around;

            width: 38vw;
            height: 50px;
            border-radius: 25px;
            background-color: $primary-button-background-color;
            margin-left: 5px;
            margin-right: 5px;

            .text {
                font-size: $font-size-14;
                font-weight: bold;
                color: white;
            }
        }

        &__green {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-around;

            width: 38vw;
            height: 50px;
            border-radius: 25px;
            background-color: $forgot-button-background-color;
            margin-left: 5px;
            margin-right: 5px;

            .text {
                font-size: $font-size-14;
                font-weight: bold;
                color: white;
            }
        }
    }

    .separator {
        width: 100%;
        height: 1px;
        margin-bottom: 20px;
        background-color: gray;
    }

    .add-cell {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row;
        width: 100%;
        margin-bottom: 20px;

        button {
            display: flex;
            align-items: center;
            justify-content: space-around;

            width: 40px;
            height: 40px;
            min-width: 40px;
            min-height: 40px;
            background-color: transparent;
            border: solid 1px gray;
            border-radius: 20px;
            margin-left: 10px;

            .icon {
                width: 12px;
                height: 12px;
                min-width: 12px;
                min-height: 12px;
                object-fit: contain;
                background-color: transparent;
            }
        }

        label {
            font-size: $font-size-14;
            font-weight: 400;
            color: white;
            width: 100%;
        }
    }
}

/*=====  End of playlistdetail  ======*/
