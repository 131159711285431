/*=============================================
=            chat            =
=============================================*/

.normal-label {
    width: 100%;
    font-size: $font-size-14;
    font-weight: 450;
    line-height: 1;
    text-align: left;
    display: inline-block;
    color: rgba($color: #ffffff, $alpha: 0.6);
    margin-top: 50px;
}

.min-70-view-height {
    min-height: 70vh;
}

.messages {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 25px;
    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
        margin-bottom: 55px;
    }
    .cell { 
        width: 100%; 
        display: flex; 
        align-items: left; 
        flex-direction: column; 
        justify-content: space-between; 
        margin-bottom: 1px; 
        -webkit-user-select: none; /* Safari */ 
        -ms-user-select: none; /* IE 10 and IE 11 */ 
        user-select: none; /* Standard syntax */
        &__content {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: space-around;
            width: 100%;
            .content {
                display: flex;
                align-items: flex-start;
                flex-direction: row;
                margin-top: 10px;
                margin-left: 20px;
                margin-bottom: 10px;
                margin-right: 20px;
                width: 100%;
                .image {
                    margin-top: 10px;
                    margin-left: 10px;
                    margin-bottom: 5px;
                    margin-right: 10px;
                    .thumbnail {
                        width: 40px;
                        height: 40px;
                        min-width: 40px;
                        min-height: 40px;
                        border-radius: 40px;
                        object-fit: cover;
                        margin-right: 10px;
                        border-width: 1px;
                        border-style: solid;
                        border-color: #5e5964;
                    }
                }

                .left-info {
                    display: flex;
                    flex-direction: column;
                    margin-top: 10px;
                    .name {
                        font-size: $font-size-13;
                        line-height: 1.3;
                        color: white;
                        margin-bottom: 3px;
                    }

                    .left-bubble {
                        font-size: $font-size-13;
                        line-height: 1.3;
                        color: white;
                        background-color: $grey--four;
                        padding: 0px;
                        border-top-right-radius: 8px;
                        border-bottom-left-radius: 8px;
                        border-bottom-right-radius: 8px;
                        display: flex;
                        flex-direction: column;
                        overflow: hidden;
                        max-width: 300px;

                        .text-message-left {
                            font-size: $font-size-15;
                            line-height: 1.3;
                            color: white;
                            padding: 10px;
                            word-break: break-all;
                        }

                        .site-url-left {
                            font-size: $font-size-13;
                            line-height: 1.1;
                            color: white;
                            padding-left: 10px;
                            padding-right: 10px;
                            padding-top: 10px;
                        }
                        .site-title-left {
                            font-size: $font-size-15;
                            line-height: 1.1;
                            font-weight: 450;
                            color: white;
                            padding-left: 10px;
                            padding-right: 10px;
                            padding-top: 10px;
                        }

                        .img-message-left {
                            min-width: 40px;
                            height: 150px;
                            border-radius: 5px;
                            object-fit: cover;
                            border-width: 1px;
                            border-style: solid;
                            border-color: #5e5964;
                        }
                    }
                }

                .right-info {
                    width: calc(100% - 15px);
                    display: flex;
                    flex-direction: row;
                    align-content: flex-end;
                    justify-content: flex-end;
                    .right-bubble {
                        font-size: $font-size-13;
                        line-height: 1.3;
                        color: white;
                        background-color: $pink;
                        padding: 0px;
                        border-top-left-radius: 8px;
                        border-bottom-left-radius: 8px;
                        border-bottom-right-radius: 8px;
                        display: flex;
                        flex-direction: column;
                        min-height: 25px;
                        overflow: hidden;
                        max-width: 300px;

                        .text-message-right {
                            font-size: $font-size-15;
                            line-height: 1.3;
                            color: white;
                            padding: 10px;
                            word-break: break-all;
                        }

                        .site-url-right {
                            font-size: $font-size-13;
                            line-height: 1.1;
                            color: white;
                            padding-left: 10px;
                            padding-right: 10px;
                            padding-top: 10px;
                        }
                        .site-title-right {
                            font-size: $font-size-15;
                            line-height: 1.1;
                            font-weight: 450;
                            color: white;
                            padding-left: 10px;
                            padding-right: 10px;
                            padding-top: 10px;
                        }

                        .img-message-right {
                            min-width: 40px;
                            height: 150px;
                            border-radius: 5px;
                            object-fit: cover;
                            border-width: 1px;
                            border-style: solid;
                            border-color: #5e5964;
                            background-color: $grey--four;
                        }
                    }
                }
            }
        }

        .time-reference {
            font-size: $font-size-13;
            line-height: 1.3;
            color: white;
            padding: 10px;
            text-align: center;
        }
    }

    .input-view {
        position: fixed;
        bottom: 100px;
        height: 64px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: black;
        z-index: 2;
        .typing-indicator {
            background-color: transparent;
            margin-left: 10px;
            margin-bottom: 5px;
            display: flex;
            flex-direction: row;
            align-items: center;
            .name-label {
                font-size: $font-size-13;
                font-weight: 500;
                line-height: 1.3;
                color: white;
            }
            .indicator-label {
                font-size: $font-size-13;
                line-height: 1.3;
                color: $grey--four;
            }
        }
        .input-controls {
            width: 100%;
            display: flex;
            flex-direction: column;
            background-color: $primary-background-color;

            .upload-images {
                width: 100%;
                display: flex;
                flex-direction: row;

                .upload-image-container {
                    display: block;
                    width: 60px;
                    height: 60px;
                    margin-top: 5px;
                    margin-right: 10px;
                    .upload-image-preview {
                        display: block;
                        width: 60px;
                        height: 60px;
                        margin-top: -15px;
                        border-width: 1px;
                        border-color: gray;
                        border-style: solid;
                        border-radius: 8px;
                        .preview {
                            width: 100%;
                            height: 100%;
                            border-radius: 8px;
                            object-fit: cover;
                            overflow: hidden;
                        }

                        .close-box {
                            position: relative;
                            top: -65px;
                            left: 45px;
                            background-color: $grey--four;
                            width: 20px;
                            height: 20px;
                            padding: 3px;
                            border-radius: 10px;
                            cursor: pointer;
                        }
                    }
                }
            }

            .input-controls-content {
                height: 100px;
                display: flex;
                flex-direction: row;

                .transform {
                    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
                        transform: translateY(125%);
                    }
                }

                .select-photo {
                    width: 40px;
                    height: 40px;
                    position: absolute;
                    margin-left: 10px;
                    margin-right: 10px;
                    bottom: 0px;
                }
                .input-area {
                    height: auto;
                    min-height: 40px;
                    background-color: $primary-background-color;
                                        border-radius: 20px;
                    border: solid 1px gray;
                    position: absolute;
                    background-color: transparent;
                    left: 60px;
                    right: 15px;
                    bottom: 0px;

                    .input-text {
                        position: absolute;
                        left: 10px;
                        width: calc(100% - 50px);
                        top: 5px;
                        color: white;
                        background-color: transparent;
                        border: none;
                        align-self: center;
                        align-items: center;
                    } 

                    .input-text-multiline {
                        transform: translateY(5px);
                        width: calc(100% - 50px);
                        color: white;
                        font-size: $font-size-16;
                        background-color: transparent;
                        border: none;
                        margin-left: 10px;
                        resize: none;
                    } 

                    .send-button {
                        width: 12px;
                        height: 12px;
                        position: absolute;
                        right: 15px;
                        top: 12px;
                    }
                }
            }
        }
    }

    .bottom {
        &-full {
            bottom: 185px;
            @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
                bottom: 165px;
            }
            &-upload {
                bottom: 185px;
                @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
                    bottom: 215px;
                }
                &-typing {
                    bottom: 185px;
                    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
                        bottom: 235px;
                    }
                }
            }
        }
        &-half {
            bottom: 110px;
            &-upload {
                bottom: 160px;
                &-typing {
                    bottom: 180px;
                }     
            }   
        }

        &-hidden {
            bottom: 75px;
            @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
                bottom: 70px;
            }
            &-upload {
                bottom: 75px;
                @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
                    bottom: 120px;
                }
                &-typing {
                    bottom: 185px;
                    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
                        bottom: 255px;
                    }
                }
            }
        }
    }
}

.messages-scroll-bound {
    width: 100%;
    // height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;   
    background-color: transparent; 
}

.chat-group-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .profile-image {
        background-color: transparent;
        display: flex;
        align-items: center;
        flex-direction: center;
        justify-content: space-evenly;
        width: 80px;
        height: 80px;
        border: 1px solid $grey--five;
        border-radius: 40px;
        margin-top: 40px;
        .image {
            width: 80px;
            height: 80px;
            min-width: 16px;
            min-height: 16px;
            border-radius: 40px;
            object-fit: cover;
            background-color: transparent;
        }
    }

    .change-group-photo-label {
        color: $blue-four;
        font-size: $font-size-14;
        font-weight: 450;
        line-height: 1;
        margin-top: 20px;
        margin-bottom: 20px;
        cursor: pointer;
    }

    .chat-settings-label {
        color: white;
        font-size: $font-size-14;
        font-weight: 450;
        line-height: 1;
        margin-top: 30px;
        margin-bottom: 0px;
        align-self: flex-start;
    }

    .group-name-container {
        display: flex;
        width: 100%;
        background-color: transparent;
        flex-direction: row;
        margin-top: 15px;

        .left-view {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .title {
                color: white;
                font-size: $font-size-12;
                font-weight: 450;
                line-height: 1;
            }

            .value {
                color: white;
                font-size: $font-size-12;
                font-weight: 450;
                line-height: 1;
            }
        }

        img {
            width: 15px;
            height: 15px;
            position: absolute;
            right: 30px;
            cursor: pointer;
        }

        .checkmark-img {
            height: 20px;
            width: 20px;
            border: 1px solid grey;
            border-radius: 3px;
        }
    }

    .group-member-container {
        display: flex;
        width: 100%;
        background-color: transparent;
        flex-direction: row;
        margin-top: 15px;
        margin-bottom: 10px;
        label {
            color: white;
            font-size: $font-size-14;
            font-weight: 450;
            line-height: 1;
            margin-top: 30px;
            margin-bottom: 0px;
            align-self: flex-start;
        }
        .blue-text-rightlabel {
            color: $blue-four;
            position: absolute;
            right: 30px;
            cursor: pointer;
        }
    }
}

.chat-member-cell {
    width: 100%;
    display: flex;
    align-self: center;
    align-items: left;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0px;
    margin-left: 2.5%;

    .cell {
        display: block;
        align-items: left;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 1px;

        img {
            margin-top: 5px;
            margin-left: 5px;
            margin-bottom: 5px;
            margin-right: 5px;

            width: 50px;
            height: 50px;
            border-radius: 25px;
            object-fit: cover;
            border-width: 1px;
            border-style: solid;
            border-color: #5e5964;
        }

        label {
            font-size: $font-size-13;
            line-height: 1.3;
            color: white;
            margin-top: 3px;
            margin-bottom: 3px;
            margin-left: 10px;
        }
    }

    .right-view {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-around;

        button {
            display: block;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;

            height: 30px;
            min-height: 30px;
            border-radius: 15px;
            object-fit: cover;
            margin-right: 10px;
            border-width: 1px;
            border-style: solid;
            border-color: #5e5964;

            img {
                margin-left: 10px;
                width: 10px;
                height: 10px;
                min-width: 10px;
                min-height: 10px;
                object-fit: cover;
            }

            .title {
                margin-left: 10px;
                margin-right: 10px;
                font-size: $font-size-13;
                line-height: 1.3;
                color: white;
            }
        }

        .menu-button {
            display: block;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            background-color: transparent;
            border: none;
            width: 40px;
            height: 40px;
            img {
                margin-left: 10px;
                width: 20px;
                height: 20px;
                min-width: 20px;
                min-height: 20px;
                object-fit: contain;
            }
        }
    }
}

.delete-button {
    width: calc(100% - 40px);
    height: 40px;
    text-align: center;
    font-size: $font-size-13;
    font-weight: 350;
    color: white;
    border: none;
    border-radius: 5px;
    background-color: red;
}

.cancel-button {
    width: calc(100% - 40px);
    height: 40px;
    text-align: center;
    font-size: $font-size-13;
    font-weight: 350;
    color: white;
    border: solid 1px #5e5964;
    border-radius: 5px;
    background-color: $primary-background-color;
}

.accept-button {
    width: calc(100% - 40px);
    height: 40px;
    text-align: center;
    font-size: $font-size-13;
    font-weight: 350;
    color: white;
    border: none;
    border-radius: 5px;
    background-color: $primary-button-background-color;
}

/**
 * ==============================================
 * Dot Typing
 * ==============================================
 */
.dot-typing {
    position: relative;
    left: -9999px;
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: white;
    color: white;
    box-shadow: 9984px 0 0 0 white, 9994px 0 0 0 white, 10004px 0 0 0 white;
    animation: dotTyping 1.5s infinite linear;
}

@keyframes dotTyping {
    0% {
        box-shadow: 9984px 0 0 0 white, 9994px 0 0 0 white, 10004px 0 0 0 white;
    }
    16.667% {
        box-shadow: 9984px -10px 0 0 white, 9994px 0 0 0 white, 10004px 0 0 0 white;
    }
    33.333% {
        box-shadow: 9984px 0 0 0 white, 9994px 0 0 0 white, 10004px 0 0 0 white;
    }
    50% {
        box-shadow: 9984px 0 0 0 white, 9994px -10px 0 0 white, 10004px 0 0 0 white;
    }
    66.667% {
        box-shadow: 9984px 0 0 0 white, 9994px 0 0 0 white, 10004px 0 0 0 white;
    }
    83.333% {
        box-shadow: 9984px 0 0 0 white, 9994px 0 0 0 white, 10004px -10px 0 0 white;
    }
    100% {
        box-shadow: 9984px 0 0 0 white, 9994px 0 0 0 white, 10004px 0 0 0 white;
    }
}

.share-dialog {
    max-height: 70vh;
    .share-rooms {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 0;
        overflow: hidden !important;
        overflow-x: scroll !important;

        &__container {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            width: 80px;

            .left-view {
                display: flex;
                align-items: center;
                flex-direction: column;
                width: 100%;

                .image {
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    justify-content: space-between;
                    // margin-right: 10px;
                    background-color: transparent;

                    .thumbnail {
                        width: 60px;
                        height: 60px;
                        flex-shrink: 0;
                        object-fit: cover;
                        border: solid 1px $black--eight;
                    }

                    .round {
                        border-radius: 30px;
                    }

                    .selected {
                        border: solid 1px $forgot-button-background-color;
                    }
                }

                .info {
                    display: flex;
                    align-items: left;
                    flex-direction: column;
                    justify-content: space-around;
                    width: 100%;
                    margin-top: 5px;
                    .song-name {
                        font-size: $font-size-11;
                        line-height: 1.1;
                        color: white;
                        text-align: center;
                    }
                }
            }
        }
    }

    .share-buttons {
        position: sticky;
        bottom: 10px;
        display: flex;
        width: 100%;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;

        .empty-button {
            font-size: $font-size-10;
            line-height: 1;
            text-align: center;
            display: block;
            height: 40px;
            float: right;
            color: $white;
            border-radius: 20px;
            background-color: $primary-background-color;
            border: 1px solid $grey--five;
        }

        .green-button {
            font-size: $font-size-13;
            line-height: 1;
            text-align: center;
            display: block;
            height: 40px;
            float: right;
            color: $white;
            border-radius: 20px;
            background-color: $forgot-button-background-color;
            border: none;
        }
    }
}

.profile-message-content {
    min-width: 40px;
    min-height: 40px;
    display: flex;
    flex-direction: row;
    background-color: $grey--six;
    justify-content: flex-start;
    padding: 8px;
    .thumbnail {
        width: 40px;
        height: 40px;
        min-width: 40px;
        min-height: 40px;
        border-radius: 40px;
        object-fit: cover;
        margin-right: 10px;
        border-width: 1px;
        border-style: solid;
        border-color: #5e5964;
    }

    .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin-top: 5px;
        align-items: flex-start;
        .artist-name {
            font-size: $font-size-14;
            line-height: 1.1;
            color: white;
            text-align: left;
        }
        .user-name {
            font-size: $font-size-12;
            line-height: 1.1;
            color: white;
            text-align: left;
        }
    }
}

.playlist-message-content {
    min-width: 40px;
    min-height: 40px;
    display: flex;
    flex-direction: row;
    background-color: $grey--six;
    justify-content: flex-start;
    .thumbnail {
        width: 70px;
        height: 70px;
        min-width: 70px;
        min-height: 70px;
        object-fit: cover;
        margin-right: 10px;
        // border: solid 1px #5e5964;
    }

    .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin-top: 5px;
        margin-right: 8px;
        align-items: flex-start;

        .artist-name {
            font-size: $font-size-14;
            line-height: 1.1;
            color: white;
            text-align: left;
        }
        .user-name {
            font-size: $font-size-11;
            line-height: 1.1;
            color: white;
            text-align: left;
        }
    }
}

.link-text {
    font-size: $font-size-13;
    line-height: 1.3;
    color: white;
    text-decoration: underline;
    padding: 10px;
    &:hover {
        text-decoration: underline;
    }
}

.pointer-style {
    cursor: pointer;
}

.attachments {
    background-color: $grey--six;
    min-height: 35px;
    // width: 250px;
}

.two-attachments {
    background-color: $grey--six;
    // width: 301px;
    max-height: 300px;
    min-height: 35px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.two-vertical-attachments {
    background-color: $grey--six;
    width: 100px;
    max-height: 300px;
    min-height: 35px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    object-fit: contain;
}

.attachment1 {
    width: 250px;
    // height: calc(250px *4/3);
    min-height: 35px;
    max-height: 300px;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    img {
        object-fit: cover;
    }

    &-250 {
        background-color: $grey--six;
        width: 250px;
        min-height: 35px;
        // height: calc(250px *4/3);
    }
    &-150 {
        width: 150px;
        min-height: 35px;
        // height: calc(125px *4/3);
    }
    &-200 {
        width: 200px;
        min-height: 35px;
        // height: calc(200px *4/3);
    }
    &-100 {
        width: 100px;
        min-height: 35px;
        // height: calc(100px *4/3);
    }
}

.show-menu {
    min-height: 150px !important;
}

.image-container {
    position: relative;
    display: flex;

    .circle-button {
        width: 40px;
        height: 40px;
        border-radius: 20px;    
        background-color: rgba($color: #404040, $alpha: 0.7);
        display: flex;
        justify-content: center; 
        flex-direction: column;  
        align-items: center; 

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        .icon {
            width: 20px;
            height: 20px;   
        }
    }
}



.caption-container {
    height: 30px;
    background-color: #303030a0;
    min-height: 30px;
}

.attachment-caption {
    background-color: transparent;
    position: relative;
    // margin-bottom: -25px;
    padding: 5px;
}

.add-caption-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .add-caption-top {
        display: flex;
        margin-top: 5px;
        .add-caption-image {
            width: 75vw;
            height: 75vw;
            max-width: 250px;
            max-height: 250px;
            border-radius: 8px;
            object-fit: cover;
            overflow: hidden;
            position: relative;
            align-self: center;
        }
        .add-caption-button {
            color: $primary-button-background-color;
            position: absolute;
            text-decoration: underline;
            font-size: $font-size-11;
            line-height: 1.1;
            right: 15px;
            cursor: pointer;
        }
    }

    .input-area {
        width: 100%;
        height: 40px;
        border-radius: 20px;
        margin-top: 20px;
        border: solid 1px gray;
        background-color: transparent;        

        .input-text {
            width: 100%;
            height: 100%;
            padding: 10px;
            color: white;
            background-color: transparent;
            border: none;
            align-self: center;
            
        }
    }
}
/*=====  End of chat  ======*/
