
.close-button {
    img {
        width: 15px;
        height: 15px;
    }
}

.cashout-section {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: baseline;
    width: 100%;

    label {
        width: 100%;
        font-size: $font-size-13;
        line-height: 1.3;
        color: white;
    }

    .description {
        color: $grey;
    }

    .error {
        width: 100%;
        font-size: $font-size-13;
        line-height: 1.3;
        color: red;
        margin-top: 10px;
    }

    .cashout-amount {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: baseline;

        img {
            width: 25px;
            object-fit: contain;
            margin-right: 10px;
        }

        label {
            font-size: $font-size-18;
            font-weight: 350;
            color: white;
            height: 100%;
            margin-top: 8px;
        }
    }

    .cashout-buttons {
        width: 100%;
        display: flex;
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        margin-top: 10px;
        background-color: transparent;

        button {
            display: block;
            width: 35%;
            border: none;
            background-color: transparent;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .cashout-form {
        display: flex;
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        margin-top: 10px;

        img {
            width: 25px;
            height: 100%;
            object-fit: contain;
            margin-right: 10px;
        }

        input,
        textarea {
            display: block;
            width: 50%;
            height: 30px;
            padding: 10px;

            background-color: $grey--six;
            border: none;
            border-radius: 5px;

            font-size: $font-size-14;
            color: $white;
            text-align: left;
        }

        ::placeholder {
            opacity: 1; /* Firefox */

            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $grey--four;
            text-align: left;
        }

        :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $grey--four;
        }

        ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $grey--four;
        }
    }

    .equal {
        font-size: $font-size-20;
        font-weight: 750;
        color: white;
        height: 100%;
        text-align: center;
        margin-top: 5px;
    }

    .equal-value {
        font-size: $font-size-20;
        font-weight: 750;
        color: white;
        height: 100%;
        text-align: center;
        margin-top: -5px;
    }

    .input-form {
        display: flex;
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        margin-top: 10px;
        width: 100%;

        .input {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: center;

            background-color: $grey--six;
            border: none;
            border-radius: 5px;
        }

        input,
        textarea {
            display: block;
            width: 100%;
            height: 40px;
            padding: 10px;

            background-color: $grey--six;
            border: none;
            border-radius: 5px;

            font-size: $font-size-14;
            color: $white;
            text-align: left;
        }

        ::placeholder {
            opacity: 1; /* Firefox */

            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $grey--four;
            text-align: left;
        }

        :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $grey--four;
        }

        ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $grey--four;
        }
    }
}

.cashout-footer {
    label {
        font-size: $font-size-10;
        font-weight: 350;
        color: gray;
        height: 100%;
        margin-top: 8px;
    }

    button {
        width: 100%;
        height: 40px;
        text-align: center;
        font-size: $font-size-13;
        font-weight: 350;
        color: white;
        border: none;
        border-radius: 20px;
        background-color: $primary-button-background-color;
    }

    button:disabled,
    button[disabled] {
        border: 1px solid #999999;
        background-color: #cccccc;
        color: #666666;
    }

    .red-button {
        width: 100%;
        height: 40px;
        text-align: center;
        font-size: $font-size-13;
        font-weight: 350;
        color: white;
        border: none;
        border-radius: 20px;
        background-color: red;
    }
}
