.common-title {
    font-size: $font-size-13;
    line-height: 1.3;
    color: white;
}

.common-right-align {
    float: right;
}

// Upload music
.media-file {
    display: flex;
}
.cover-art {
    display: flex;
}
.file-preview-container {
    display: flex;
    width: 100%;
    height: 60px;
    margin-top: 5px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .file-name {
        font-size: $font-size-12;
        color: white;
    }
    .file-preview {
        display: flex;
        width: 60px;
        height: 60px;
        border-width: 1px;
        border-color: gray;
        border-style: solid;
        border-radius: 8px;
        overflow: hidden;
        .preview {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.common-check-container {
    display: flex;
    align-items: center;
    flex-direction: center;
    justify-content: center;

    .container {
        display: block;
        position: relative;
        padding-left: 28px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: $font-size-13;
        font-weight: 350;
        color: white;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default checkbox */
    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        border: 1px solid grey;
        border-radius: 3px;
    }

    /* On mouse-over, add a grey background color */
    //   .container:hover input ~ .checkmark {
    //     background-color: #ccc;
    //   }

    /* When the checkbox is checked, add a blue background */
    .container input:checked ~ .checkmark {
        background-color: $primary-button-background-color;
        border: 1px solid $primary-button-background-color;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .container input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .container .checkmark:after {
        left: 6.5px;
        top: 3.5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}

/*
.empty-button {
    font-size: $font-size-10;
    line-height: 1;
    text-align: center;
    display: block;
    height: 30px;
    float: right;
    color: $white;
    border-radius: 15px;
    background-color: transparent;
    border: 1px solid $grey--five;
}
.pink-button {
    font-size: $font-size-10;
    line-height: 1;
    text-align: center;
    display: block;
    height: 30px;
    float: right;
    color: $white;
    border-radius: 15px;
    background-color: $primary-button-background-color;
    border: none;
}

.green-button {
    font-size: $font-size-10;
    line-height: 1;
    text-align: center;
    display: block;
    height: 30px;
    float: right;
    color: $white;
    border-radius: 15px;
    background-color: $forgot-button-background-color;
    border: none;
}
*/