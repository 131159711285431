/*=============================================
=            Common            =
=============================================*/

.bg-img {
    background-repeat: no-repeat;
    background-size: cover;
}

.border-bottom {
    border-bottom: 1px solid $grey--seven !important;
    &--medium {
        border-bottom: 2px solid $grey--seven !important;
    }
    &--thick {
        border-bottom: 3px solid $grey--seven !important;
    }
}

.body-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;

    &.active-overlay {
        overflow: hidden;
        &:before {
            position: fixed;
            z-index: 999;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            content: "";

            background: transparent;
        }
    }
}


.max-width-desktop {
    width: 100%;
    min-width: 450px;
    max-width: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    float: center;
}

.back-button {
    width: 30px;
    height: 30px;
    min-width: 20px;
    min-height: 20px;
    background-color: transparent;
    display: flex;
    align-items: center;
    border: none;

    .image {
        height: 13px;
        min-width: 13px;
        min-height: 13px;
        object-fit: contain;
        background-color: transparent;
    }
}

.full-width {
    width: 100%;
    max-width: 600px;
}

.max-width {
    max-width: 600px;
}

.min-width {
    &-message-bubble {
        min-width: 250px;
    }
}
.min-height {
    &-100vh {
        min-height: 100vh;
    }
}

.button-bg-color {
    &--primary {
        background-color: $primary-button-background-color;
    }
    &--green {
        background-color: $forgot-button-background-color;
    }
    &--red {
        background-color: red;
    }

    &--transparent {
        background-color: transparent;
    }
}

.border-color {
    &--gray {
        border-color: gray;
        border-width: 1px;
    }

    &--transparent {
        border-color: transparent;
        border-width: 0px;
    }
}

.height-ratio {
    &-11 {
        height: 100%;
    }
    &-12 {
        height: calc(100% /2);
    }
    &-13 {
        height: calc(100% /3);
    }
}

.height-px {
    &-44 {
        height: 44px;
    }
    &-50 {
        height: 50px;
    }
    &-60 {
        height: 60px;
    }
    &-80 {
        height: 80px;
    }
    &-100 {
        height: 100px;
    }
    &-150 {
        height: 150px;
    }
    &-200 {
        height: 200px;
    }
    &-250 {
        height: 250px;
    }
    &-300 {
        height: 300px;
    }
}

.width-px {
    &-50 {
        width: 50px;
    }
    &-60 {
        width: 60px;
    }
    &-80 {
        width: 80px;
    }
    &-100 {
        width: 100px;
    }
    &-150 {
        width: 150px;
    }
    &-200 {
        width: 200px;
    }
    &-250 {
        width: 250px;
    }
    &-300 {
        width: 300px;
    }
    &-350 {
        width: 350px;
    }
    &-450 {
        width: 450px;
    }
    &-600 {
        width: 600px;
    }
}

.width-pc {
    &-10 {
        width: 10%;
    }
    &-20 {
        width: 20%;
    }
    &-30 {
        width: 30%;
    }
    &-40 {
        width: 40%;
    }
    &-45 {
        width: 45%;
    }
    &-50 {
        width: 50%;
    }
    &-60 {
        width: 60%;
    }
    &-70 {
        width: 70%;
    }
    &-80 {
        width: 80%;
    }
    &-90 {
        width: 90%;
    }
    &-100 {
        width: 100%;
    }
}

.size-px {
    &-auto {
        width: auto;
        height: 100%;
    }
    &-15 {
        min-width: 15px;
        min-height: 15px;
        width: 15px;
        height: 15px;
    }
    &-18 {
        min-width: 18px;
        min-height: 18px;
        width: 18px;
        height: 18px;
    }
    &-20 {
        min-width: 20px;
        min-height: 20px;
        width: 20px;
        height: 20px;
    }
    &-30 {
        min-width: 30px;
        min-height: 30px;
        width: 30px;
        height: 30px;
    }
    &-40 {
        min-width: 40px;
        min-height: 40px;
        width: 40px;
        height: 40px;
    }
    &-50 {
        min-width: 50px;
        min-height: 50px;
        width: 50px;
        height: 50px;
    }
    &-60 {
        min-width: 60px;
        min-height: 60px;
        width: 60px;
        height: 60px;
    }
    &-80 {
        min-width: 80px;
        min-height: 80px;
        width: 80px;
        height: 80px;
    }
    &-100 {
        min-width: 100px;
        min-height: 100px;
        width: 100px;
        height: 100px;
    }
    &-150 {
        min-width: 150px;
        min-height: 150px;
        width: 150px;
        height: 150px;
    }
    &-200 {
        min-width: 200px;
        min-height: 200px;
        width: 200px;
        height: 200px;
    }
    &-250 {
        min-width: 250px;
        min-height: 250px;
        width: 250px;
        height: 250px;
    }
    &-300 {
        min-width: 300px;
        min-height: 300px;
        width: 300px;
        height: 300px;
    }
}

.bg-color {
    &-transparent {
        background-color: transparent !important;
    }
    &-primary {
        background-color: $primary-background-color;
    }

    &-button-primary {
        background-color: $primary-button-background-color;
    }

    &-dark-gray {
        background-color: rgb(102, 101, 101);
    }

    &-test {
        background-color: red;
    }
    &-test1 {
        background-color: blue;
    }
}

.object-fit {
    &-contain {
        object-fit: contain;
    }
    &-cover {
        object-fit: cover;
    }
    &-fill {
        object-fit: fill;
    }
}

.rounded {
    &-5 {
        border-radius: 5px;
    }
    &-8 {
        border-radius: 8px;
    }
    &-15 {
        border-radius: 15px;
    }
    &-20 {
        border-radius: 20px;
    }
    &-22 {
        border-radius: 22px;
    }
    &-25 {
        border-radius: 25px;
    }
}

.border {
    &-none {
        border: none;
    }
    &-1 {
        border: solid 1px #5e5964;
    }
    &-2 {
        border: solid 2px #5e5964;
    }
}

.align { 
    &-horizontal {
        display: flex; 
        flex-direction: row; 
        justify-content: flex-start; 
        align-items: center; 
        height: auto; 
        width: auto;
    }
    &-vertical {
        display: flex; 
        flex-direction: column; 
        justify-content: flex-start; 
        height: auto; 
        width: auto;
    }
    &-center {
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;    
    }
} 

.aligned { 
    &-left {
        align-self: left; 
    }
    &-center {
        align-self: center; 
    }
    &-right {
        align-self: right; 
    }
} 

.float { 
    &-left {
        float:left;
    }
    &-right {
        float:right;
    }
    &-top {
        float:top;
    }
    &-bottom {
        float:bottom;
    }
} 

.position { 
    &-relative {
        position: relative;
    }
    &-absolute {
        position: absolute;
    }
    &-fixed {
        position: fixed;
    }
    &-inherit {
        position: inherit;
    }
} 

.font-weight {
    &-regular {
        font-weight: 300;
    }
    &-medium {
        font-weight: 500;
    }
    &-bold {
        font-weight: 700;
    }
}
 
.font-size {
    &-11 {
        font-size: max(11px, 1.1vw);
    }
    &-13 {
        font-size: max(13px, 1.3vw);
    }
    &-15 {
        font-size: max(15px, 1.5vw);
    }
    &-16 {
        font-size: max(16px, 1.7vw);
    }
    &-17 {
        font-size: max(17px, 1.7vw);
    }
    &-19 {
        font-size: max(19px, 1.9vw);
    }
    &-21 {
        font-size: max(21px, 2.1vw);
    }
}

.text-color {
    &-white {
        color: white;
    }
    &-light-gray {
        color: rgb(204, 197, 197);
    }
    &-gray {
        color: gray;
    }
    &-dark-gray {
        color: rgb(102, 101, 101);
    }
    &-black {
        color: black;
    }
    &-pink {
        color: $primary-button-background-color;
    }
}

.space { 
    display: flex; 
 
    &-evenly { 
        justify-content: space-evenly; 
    } 
    &-between { 
        justify-content: space-between; 
    } 
    &-flex-end { 
        justify-content: flex-end; 
    } 
    &-flex-start { 
        justify-content: flex-start; 
    } 
}

.margin {
    &-all {
        &-5 {
            margin: 5px;
        }
        &-10 {
            margin: 10px;
        }
    }

    &-left {
        &-1 {
            margin-left: 1px;
        }
    }

    &-right {
        &-1 {
            margin-right: 1px;
        }
        &-30 {
            margin-right: 30px;
        }
    }

    &-top {
        &-1 {
            margin-top: 1px;
        }

        &-120 {
            margin-top: 120px;
        }
    }

    &-bottom {
        &-1 {
            margin-bottom: 1px;
        }
    }
}

// .box {
//     &-center {
//         position: relative;
//     }
// }

.self {
    &-center {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
    }

    &-left {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        left: 0;
    }
    &-right {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        right: 0;
    }
}

.box {
    &-center {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-center-relative {
        position: relative;
    }

    &-side {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-equal {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }
    &-around {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
}

.button {
    &-full-size {
        background-color: $primary-button-background-color;
        border: none;
        border-radius: 22px;
        height: 44px;
        width: 100%;
        
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        cursor: pointer;

        .title {
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;

            font-size: max(13px, 1.3vw);
            font-weight: 450;
            color: white;

            cursor: pointer;
        }

        &:disabled {
            background-color: $primary-background-color;
            border: solid 1px #5e5964;
        }
    }
}

.common-button {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    z-index: 0;
    img {
        background-color: transparent;
        object-fit: contain;
        height: 40px;
        width: 40px;
    }
    label {
        font-family: $body-font-family;
        font-size: $font-size-14;
        font-weight: 450;
        color: white;
        text-align: center;
        width: 100%;
        margin-top: 3px;
    }
}

.notification_reddot {
    background-color: red;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(-240%, 250%);
}

.disable_scroll {
    overflow: hidden !important;
    -webkit-overflow-scrolling: touch;
}

.disable-pull-to-refresh {
    overscroll-behavior: contain !important;
}

.disable-dbl-tap-zoom {
    touch-action: manipulation;
}

.fix-height {
    &-dragging {
        height: calc(100vh - 100px);
    }
    &-default {
        height: 100vh;
    }
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

//Custom Slider Bar
.example-track {
    &-0 {
        background-color: rgb(2, 90, 253)!important;
        height: 5px;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
    }
    &-1 {
        background-color: rgb(87, 86, 86)!important;
        height: 5px;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
    }
}
//End Custom Slider


.smooth-dnd-container.vertical .smooth-dnd-draggable-wrapper {
    overflow: visible !important;
}


//Custom Tab
.react-tabs__tab {
    // width: 50vw;
    text-align: center;
}

.tab {
    &-home {
        width: 50vw;
        max-width: 300px;
    }
}
//End Custom Tab


/*=====  End of Common  ======*/