/*=============================================
=            Footer            =
=============================================*/

.footer-nav-wrapper {
    position: fixed;
    z-index: 99;
    bottom: 0;
    left: 0;

    display: flex;
    justify-content: space-between;

    flex-direction: column;

    width: 100%;
    // padding: 0 10px;

    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: $grey--six;
    box-shadow: 0 2px 10px rgba($black, 0.16);
}

.tab-bar {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: $grey--six;
    // box-shadow: 0 2px 10px rgba($black, 0.16);
}

.footer-player-hidden-wrapper {
    display: flex;
    justify-content: left;
    width: 0;
    height: 0;
    padding: 0;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
    background-color: $primary-background-color;
    overflow: hidden;
    z-index: 10;
}

.miniplayer-mode {
    &-full {
       height: 110px;
       @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
            height: 95px;
        }
    }
    &-half {
        height: 35px;
        @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
            height: 40px;
        }
    }
}

.footer-miniplayer-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    // height: 35px;

    // @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    //     height: 95px;
    // }

    align-items: center;
    flex-direction: column;
    background-color: transparent;
    border-bottom: 1px solid #60606060;
    z-index: 10;

    .footer-player-control-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 34px;
        align-items: center;
        flex-direction: row;
        background-color: transparent;

        z-index: 10;

        .main_button {
            width: 40px;
            height: 40px;
            min-width: 40px;
            min-height: 40px;
            border: none;

            display: flex;
            align-items: center;
            justify-content: space-around;
            background-color: transparent;
            border: none;

            img {
                width: 20px;
                height: 20px;
            }
        }

        .sub_button {
            width: 40px;
            height: 40px;
            min-width: 40px;
            min-height: 40px;
            border: none;

            display: flex;
            align-items: center;
            justify-content: space-around;
            background-color: transparent;
            border: none;

            img {
                width: 15px;
                height: 15px;
            }
        }


        .footer-queue-wrapper {
            display: flex;
            justify-content: right;
            width: 100%;
            height: 0px;
            align-items: right;
            flex-direction: row-reverse;
            background-color: transparent;    
            
            .main_button {
                width: 40px;
                height: 40px;
                min-width: 40px;
                min-height: 40px;
                border: none;
    
                display: flex;
                align-items: center;
                justify-content: space-around;
                background-color: transparent;
                border: none;
    
                img {
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }

    .bottom-line {
        border-bottom: 1px solid #60606060;
    }

    .footer-player-info-wrapper {
        display: flex;
        justify-content: left;
        width: 100%;
        height: auto;
        padding-left: 15px;
        padding-right: 15px;

        align-items: flex-start;
        flex-direction: row;
        justify-content: space-between;
        background-color: transparent;

        .footer-player-container {
            height: 0px;
            @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
                height: 50px;
                }
            width: 0;
            overflow: hidden;
            padding-left: 50px;
            margin-top: 3px;
            background: transparent;
            position: relative;
        }

        // .info-height {
        //     &-full {
        //         height: 0px;
        //         @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
        //             height: 50px;
        //         }
        //     }
        //     &-half {
        //         height: 0px;
        //         @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
        //             height: 0px;
        //         }
        //     }
        // }
    }
}

.footer-player {
    position: absolute;
    transform: translate(-100%, 5px);
    width: 50px;
    height: 50px;
    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
        transform: translate(-100%, 1px);
        width: 48px;
        height: 48px;
        }

    border-radius: 8px;
    object-fit: cover;
    border-width: 1px;
    border-style: solid;
    border-color: #5e59646c;
    background-color: transparent;
}

.footer-player-info {
    display: flex;
    justify-content: left;
    width: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    background-color: transparent;
    padding-left: 20px;
    
    .info-area {
        display: flex;
        align-items: center;
        width: 100%;
        background-color: transparent;
        .name-area {
            display: flex;
            width: 100%;
            float: left;
            flex-direction: column;
            margin-top: 2px;
            justify-content: space-between;
            background-color: transparent;
            .songname {
                font-size: $font-size-14;
                font-weight: 600;
                line-height: 1.3;
                color: white;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1; /* number of lines to show */
                -webkit-box-orient: vertical;
            }
            .username {
                font-size: $font-size-13;
                line-height: 1.3;
                color: white;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1; /* number of lines to show */
                -webkit-box-orient: vertical;
            }
        }
        .play-button {
            width: 36px;
            height: 36px;
            .icon {
                width: 20px;
                height: 20px;
                margin-left: 8px;
                margin-top: 8px;
            }
        }
    }

    .sider-controls {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: transparent;
        .progress-controls {
            width: 100%;
            margin-top: 0px;
            display: inline-block;
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            background-color: transparent;
            .time {
                font-size: $font-size-12;
                font-weight: 400;
                color: white;
                padding: 10px, 10px;
                white-space: nowrap;
                min-width: 30px;
            }
        }
    }
}


.footer-nav-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.footer-nav-single {
    position: relative;

    display: block;

    width: 85px;
    height: 65px;

    text-align: center;

    border-radius: 50%;

    @media #{$extra-extra-small-mobile} {
        width: 60px;
        height: 60px;
    }

    span {
        font-size: $font-size-12;

        color: $grey--four;
    }
    svg,
    img {
        display: block;

        margin: 0 auto;
        margin-bottom: 5px;
        @media #{$extra-extra-small-mobile} {
            width: 20px;
            height: 20px;
        }
    }

    .menu-wrapper {
        line-height: 1;

        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
    }

    .icon {
        width: 20px;
        height: 20px;
    }

    .indicator {
        width: 20px;
        height: 4px;
        margin-top: 5px;
    }

    .icon-container {
        position: relative;
        display: flex;    

        .red-dot {
            width: 10px;
            height: 10px;
            margin-top: 0px;
            background-color: red;
            border-radius: 5px;


            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(50%,-150%);
        }
    }    

    &:before {
        position: absolute;
        z-index: -1;
        top: -5px;
        left: -5px;

        visibility: hidden;

        width: calc(100% + 10px);
        height: calc(100% + 10px);

        content: "";

        opacity: 0;
        border-radius: 50%;
        background-color: $white;
    }
}

/*=====  End of Footer ======*/
