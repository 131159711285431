/*=============================================
=            support            =
=============================================*/

.support-page {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
}

.support-page-header {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;

    padding: 30px 0;

    &__title {
        margin-bottom: 50px;
    }

    &__button-close {
        font-size: $font-size-10;
        line-height: 1;
        text-align: center;
        display: block;
        width: 80px;
        height: 30px;
        float: right;
        color: $white;
        border-radius: 15px;
        background-color: $primary-button-background-color;
        border: none;
    }
}

.support-page-body {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    .balance-form {
        width: 100%;
        padding: 40px 8px;
        background-color: $primary-background-color;

        &__single-field {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            label {
                font-size: $font-size-14;
                font-weight: 350;
                margin-bottom: 10px;
                color: white;
            }

            .value-container {
                height: 0px;
                display: block;
                align-items: center;
                flex-direction: row;
                justify-content: baseline;

                img {
                    width: 25px;
                    object-fit: contain;
                    margin-right: 10px;
                    margin-bottom: 5px;
                }
    
                label {
                    font-size: $font-size-18;
                    font-weight: 350;
                    color: white;
                }
            }
        }
    }

    .amount-form {
        width: 100%;
        background-color: $primary-background-color;

        &__single-field {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            label {
                font-size: $font-size-14;
                font-weight: 350;
                margin-bottom: 10px;
                color: white;
            }

            .input {
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: center;

                background-color: $grey--six;
                border: none;
                border-radius: 5px;
            }

            input,
            textarea {
                display: block;
                height: 35px;
                width: 60px;
                padding: 10px;

                background-color: $grey--six;
                border: none;
                border-radius: 5px;

                font-size: $font-size-14;
                color: $white;
                text-align: center;
            }

            ::placeholder {
                opacity: 1; /* Firefox */

                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $grey--four;
                text-align: center;
            }

            :-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: $grey--four;
            }

            ::-ms-input-placeholder {
                /* Microsoft Edge */
                color: $grey--four;
            }
        }
    }

    .amount-form {
        width: 100%;
        padding:30px 8px;
        background-color: $primary-background-color;

        &__single-field {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            label {
                font-size: $font-size-14;
                font-weight: 350;
                margin-bottom: 10px;
                color: white;
            }

            img {
                margin-left: 10px;
                width: 25px;
                height: 25px;
                object-fit: contain;
            }
        }
    }

    .description-form {
        width: 100%;
        padding: 0px 8px;
        background-color: $primary-background-color;

        &__single-field {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            label {
                font-size: $font-size-14;
                font-weight: 350;
                margin-bottom: 10px;
                color: white;
            }
            input,
            textarea {
                font-size: $font-size-14;

                display: block;
                background-color: $grey--six;
                height: 44px;
                width: 100%;
                padding: 10px;

                color: $white;
                border: none;
                border-radius: 5px;
            }

            ::placeholder {
                opacity: 1; /* Firefox */

                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $grey--four;
                text-align: center;
            }

            :-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: $grey--four;
            }

            ::-ms-input-placeholder {
                /* Microsoft Edge */
                color: $grey--four;
            }
        }
    }
}

.support-page-footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 40px;
    padding-bottom: 20px;

    button {
        font-size: $font-size-13;
        font-weight: 400;
        line-height: 1;
        text-align: center;
        display: block;
        width: 100%;
        height: 44px;
        float: center;
        color: $white;
        border-radius: 22px;
        background-color: $primary-button-background-color;
        border: none;
    }
}

/*=====  End of feeds  ======*/
