.connection-cell {
    width: 95%;
    display: flex;
    align-self: center;
    align-items: left;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    margin-left: 2.5%;
    // margin-right: 20px;
    background-color: #28252b;
    border: solid 1px #5e5964;
    border-radius: 8px;

    .cell {
        display: block;
        align-items: left;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 1px;

        img {
            margin-top: 15px;
            margin-left: 10px;
            margin-bottom: 15px;
            margin-right: 10px;

            width: 40px;
            height: 40px;
            min-width: 40px;
            min-height: 40px;
            border-radius: 40px;
            object-fit: cover;
            margin-right: 10px;
            border-width: 1px;
            border-style: solid;
            border-color: #5e5964;
        }

        label {
            font-size: $font-size-13;
            line-height: 1.3;
            color: white;
            margin-top: 3px;
            margin-bottom: 3px;
        }
    }

    .right-view {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-around;

        button {
            display: block;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;

            height: 30px;
            min-height: 30px;
            border-radius: 15px;
            object-fit: cover;
            margin-right: 10px;
            border-width: 1px;
            border-style: solid;
            border-color: #5e5964;

            img {
                margin-left: 10px;
                width: 10px;
                height: 10px;
                min-width: 10px;
                min-height: 10px;
                object-fit: cover;
            }

            .title {
                margin-left: 10px;
                margin-right: 10px;
                font-size: $font-size-13;
                line-height: 1.3;
                color: white;
            }
        }
    }
}

.new-group-cell {
    width: 95%;
    display: flex;
    align-self: center;
    align-items: left;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    margin-left: 2.5%;

    .cell {
        display: block;
        align-items: left;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 1px;

        img {
            margin-top: 15px;
            margin-left: 10px;
            margin-bottom: 15px;
            margin-right: 10px;

            width: 40px;
            height: 40px;
            min-width: 40px;
            min-height: 40px;
            object-fit: cover;
            margin-right: 10px;
        }

        label {
            font-size: $font-size-13;
            line-height: 1.3;
            color: white;
            margin-top: 3px;
            margin-bottom: 3px;
        }
    }
}

.group-photo-wrapper {
    width: 100%;
    display: flex;
    align-self: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;

    img {
        width: 30%;
        aspect-ratio: 1;
        object-fit: cover;
        align-self: center;

        background-color: #28252b;
        border: solid 1px #5e5964;
        border-radius: 100%;
    }
}
