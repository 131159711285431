/*=============================================
=            postdetail            =
=============================================*/

/* postdetail header */

.postdetail-header {
    width: 100%;
    display: flex;
    margin-top: 20px;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    position: relative;

    &__image {
        display: flex;
        align-items: left;
        flex-direction: column;
        justify-content: space-around;
        position: relative;
        width: 100%;
        height: 50vh;
        // padding-left: 40px;
        // padding-right: 40px;

        .back-button {
            z-index: 1000;
            border-radius: 20px;
            width: 40px;
            height: 40px;
            background-color: rgba($color: #000000, $alpha: 0.5);
            display: flex;
            position: absolute;
            top: 10px;
            left: 10px;

            .button-img {
                width: 40px;
                height: 40px;
                padding: 5px;
                object-fit: scale-down;
                cursor: pointer;
            }
        }

        .cover-img {
            width: 100%;
            height: 100%;
            min-width: 100%;
            min-height: 300px;
            border-radius: 8px;
            flex-shrink: 0;
            border-color: gray;
            object-fit: cover;
        }
    }

    .average-rating {
        font-size: $font-size-14;
        font-weight: 400;
        color: white;
        margin-top: 160px;
    }

    &__profile-container {
        width: calc(100% - 20px);
        margin-top: 0px;
        display: flex;
        position: relative;
        bottom: 230px;
        margin-bottom: -230px;
        align-items: left;
        flex-direction: column;
        justify-content: space-around;

        .profile {
            display: flex;
            align-items: left;
            flex-direction: row;
            img {
                width: 60px;
                height: 60px;
                min-width: 60px;
                min-height: 60px;
                border-radius: 30px;
                border-width: 1px;
                border-color: gray;
                object-fit: cover;
            }
        }

        .profile-info {
            display: flex;
            align-items: center;
            flex-direction: row;
            background-color: transparent;
            width: 100%;
            justify-content: space-between;

            .profile-info-text {
                display: flex;
                flex-direction: column;
                margin-top: 10px;

                .song-name {
                    font-size: $font-size-14;
                    font-weight: 700;
                    color: white;
                    height: 26px;
                    line-height: 1.9;
                    background-color: rgba($color: #000000, $alpha: 0.5);
                    border-radius: 13px;
                    padding-left: 10px;
                    padding-right: 10px;
                    align-self: flex-start;
                }
                .author-name {
                    font-size: $font-size-13;
                    font-weight: 400;
                    color: white;
                    background-color: rgba($color: #000000, $alpha: 0.5);
                    height: 26px;
                    border-radius: 13px;
                    padding-left: 10px;
                    padding-right: 10px;
                    align-self: flex-start;
                }
            }

            .functional-buttons {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                background-color: transparent;
                transform: translateY(55%);

                .review-btn {
                    font-size: $font-size-10;
                    line-height: 1;
                    text-align: center;
                    display: block;
                    width: 80px;
                    height: 30px;
                    margin-right: 10px;
                    margin-bottom: 5px;
                    float: right;
                    color: $white;
                    border-radius: 15px;
                    border: none;
                    background-color: $primary-button-background-color;
                }
                .menu-button {
                    display: flex;
                    width: 30px;
                    height: 30px;
                    border: none;
                    border-radius: 15px;
                    margin: 5px;
                    flex-direction: column;
                    align-items: center;
                    background-color: rgba($color: #000, $alpha: 0.5);
                    .menu-button-image {
                        width: 30px;
                        height: 30px;
                        padding: 7px;
                        object-fit: contain;
                        background-color: transparent;
                    }
                }
            }

            .space-num-views {
                margin-top: 10px;
            }

            .space-button {
                margin-bottom: -20px;
            }
        }
        .space-profile {
            margin-top: -10px;
        }
    }

    &__player {
        width: 100%;
        display: inline-block;
        position: relative;

        // display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;
    }

    &__controls {
        width: 100%;
        display: inline-block;
        position: relative;
        margin-top: 20px;
        margin-bottom: 0px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;

        .progress {
            width: 90%;
            height: 50px;
            margin-top: 0px;
            margin-bottom: 40px;
            padding-top: 15px;
            display: inline-block;
            position: relative;
            margin-bottom: 0px;
            display: flex;
            // align-items: center;
            flex-direction: row;
            justify-content: space-around;
            background-color: transparent;
            .time {
                font-size: $font-size-14;
                font-weight: 400;
                color: white;
                padding: 10px, 10px;
                margin-top: 3px;
            }
        }

        .buttons {
            width: 50%;
            display: inline-block;
            position: relative;
            margin-bottom: 0px;
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-around;
        }
    }

    &__content {
        flex-basis: calc(100% - 135px);
        .name {
            font-size: $font-size-16;
            font-weight: 700;
        }
    }
    .postdetail-info {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;

        &-block {
            &__username {
                font-size: $font-size-12;
                font-weight: 700;
                color: $white;
                text-align: center;
                margin-bottom: 5px;
            }
            &__usertype {
                font-size: $font-size-10;
                line-height: 1;
                color: $grey;
                text-align: center;
                margin-bottom: 15px;
            }
            &__usertag {
                font-size: $font-size-10;
                line-height: 1;
                color: $grey;
                text-align: center;
                margin-bottom: 15px;
            }
        }
    }

    .postdetail-connection {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;

        &__region {
            font-size: $font-size-12;
            color: $grey;
        }

        &__separator {
            width: 1px;
            height: 20px;
            background-color: $grey;
            margin-left: 10px;
        }

        &__icon {
            width: 20px;
            height: 20px;
            border-radius: 10px;
            margin-left: 10px;
            margin-right: 3px;
        }

        &__number {
            font-size: $font-size-12;
            color: $grey;
        }

        &__btn {
            font-size: $font-size-14;
            font-weight: bold;
            line-height: 1;
            text-align: center;
            display: block;

            width: 100%;
            height: 50px;
            margin-left: 15px;
            margin-right: 15px;
            // margin-bottom: 40px;
            padding: 18px;

            color: $white;
            border-radius: 15px;
            background-color: $primary-button-background-color;
        }
    }

    .postdetail-buttons {
        margin-top: 20px;

        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;

        &__social {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-around;

            width: 50px;
            height: 50px;

            border-radius: 15px;
            background-color: $grey--nineteen;
            border-color: $grey--nineteen;
            margin-left: 5px;
            margin-right: 5px;

            .icon {
                display: block;
                max-width: 30px;
                max-height: 30px;
                width: auto;
                height: auto;
            }
        }

        &__primary {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-around;

            width: 38%;
            height: 50px;
            border-radius: 25px;
            background-color: $primary-button-background-color;
            margin-left: 5px;
            margin-right: 5px;

            .text {
                font-size: $font-size-14;
                font-weight: bold;
                color: white;
            }
        }

        &__green {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-around;

            width: 38%;
            height: 50px;
            border-radius: 25px;
            background-color: $forgot-button-background-color;
            margin-left: 5px;
            margin-right: 5px;

            .text {
                font-size: $font-size-14;
                font-weight: bold;
                color: white;
            }
        }
    }

    .average-rating-slider {
        background-color: transparent;
        height: 3px;
        width: 90%;
        margin-top: 20px;
        margin-bottom: 40px;
        border-radius: 1.5px;
        color: white;
        background-color: $primary-button-background-color;
        // border: 3px solid $primary-button-background-color;

        img {
            width: 20px;
            height: 25px;
            margin-bottom: 5px;
        }

        .example-track {
            position: relative;
            background: transparent;
        }
        .example-track.example-track-1 {
            background: #f00;
        }
        .example-track.example-track-2 {
            background: #0f0;
        }

        .example-mark {
            position: absolute;
            width: 8px;
            height: 8px;
            border: 2px solid #000;
            background-color: #fff;
            cursor: pointer;
            border-radius: 50%;
            vertical-align: middle;
        }

        .example-thumb {
            font-size: 1em;
            text-align: center;
            background-color: transparent;
            color: white;
            width: 20px;
            height: 50px;
            margin-top: -22px;
        }

        .example-thumb.active {
            background-color: transparent;
        }
    }
    .reviewContainer {
        width: 100%;

        margin-top: 20px;

        display: flex;
        align-items: left;
        flex-direction: column;
        justify-content: space-around;
    }

    .reviews {
        display: flex;
        align-items: left;
        flex-direction: row;
        justify-content: space-between;
        background-color: #28252b;

        border-radius: 8px;
        border-width: 1px;
        border-style: solid;
        border-color: #5e5964;

        width: 100%;
        // margin-left: 10px;
        margin-bottom: 15px;

        &__content {
            display: flex;
            align-items: left;
            flex-direction: column;
            justify-content: space-around;

            .image {
                margin-top: 10px;
                margin-left: 10px;
                margin-bottom: 5px;
                margin-right: 10px;

                .thumbnail {
                    width: 30px;
                    height: 30px;
                    min-width: 30px;
                    min-height: 30px;
                    border-radius: 15px;
                    object-fit: cover;
                    margin-right: 10px;
                    border-width: 1px;
                    border-style: solid;
                    border-color: #5e5964;
                }

                .user-name {
                    font-size: $font-size-11;
                    line-height: 1;
                    text-align: left;
                    display: inline-block;
                    margin-top: 5px;
                    color: white;
                }
            }

            .content {
                display: flex;
                align-items: left;
                flex-direction: column;
                justify-content: space-around;
                margin-top: 10px;
                margin-left: 10px;
                margin-bottom: 10px;
                margin-right: 10px;
                .comment {
                    font-size: $font-size-13;
                    line-height: 1.3;
                    color: white;
                }
                .time-stamp {
                    font-size: $font-size-11;
                    line-height: 1;
                    text-align: left;
                    display: inline-block;
                    margin-top: 15px;
                    color: $grey--four;
                }
            }
        }

        &__vote {
            display: flex;
            align-items: right;
            flex-direction: column;
            justify-content: space-around;
            margin-top: 10px;
            margin-left: 10px;
            margin-right: 10px;
            min-width: 30px;

            .review-rating {
                display: flex;
                align-items: right;
                flex-direction: row;
                justify-content: flex-end;
                margin-bottom: 10px;

                img {
                    width: 25px;
                    height: 25px;
                }

                label {
                    font-size: $font-size-13;
                    line-height: 1;
                    text-align: left;
                    display: inline-block;
                    align-self: flex-end;
                    margin-bottom: 0px;
                    color: white;
                }
            }

            .vote {
                display: flex;
                align-items: right;
                flex-direction: row;
                justify-content: flex-end;
                margin-bottom: 10px;

                .number {
                    font-size: $font-size-13;
                    line-height: 1.3;
                    color: white;
                }

                .image {
                    width: 15px;
                    height: 15px;
                    min-width: 15px;
                    min-height: 15px;
                    object-fit: cover;
                    margin-left: 5px;
                }
            }
        }

        .actions {
            display: flex;
            align-items: right;
            flex-direction: row;
            justify-content: flex-end;
            height: 30px;

            .header-button {
                width: 30px;
                height: 30px;
                margin-right: 0px;

                .edit-image {
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }

    .replies {
        width: 85%;
        margin-left: 10%;
        background-color: #48454b;
    }

    .hyper-link {
        font-size: $font-size-11;
        line-height: 1;
        text-align: left;
        display: inline-block;
        color: white;
        text-decoration: underline;
        margin-top: -5px;
        margin-bottom: 20px;
        margin-left: 5%;
    }

    .header-button {
        margin-right: -10px;
        margin-bottom: -12px;
    }
}

.progress-slider {
    background-color: blanchedalmond;
    height: 5px;
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 2.5px;
    color: white;
    background-color: royalblue;

    img {
        width: 20px;
        height: 25px;
        margin-bottom: 5px;
    }
    .example-track {
        position: relative;
        background: transparent;
    }
    .example-track.example-track-1 {
        background: #f00;
    }
    .example-track.example-track-2 {
        background: #0f0;
    }
    .example-mark {
        position: absolute;
        width: 8px;
        height: 8px;
        border: 2px solid #000;
        background-color: #fff;
        cursor: pointer;
        border-radius: 50%;
        vertical-align: middle;
    }

    .example-thumb {
        font-size: 1em;
        text-align: center;
        background-color: white;
        color: white;
        width: 16px;
        height: 16px;
        margin-top: -5px;
        border-radius: 8px;
    }

    .example-thumb.active {
        background-color: white;
    }
}

.description-label {
    font-size: $font-size-16;
    font-weight: 400;
    color: white;
}

.report-cell {
    display: flex;
    align-items: right;
    flex-direction: column;
    justify-content: space-between;
    height: 55px;

    &__body {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        height: 55px;
        margin-top: 8px;

        label {
            font-size: $font-size-16;
            font-weight: 400;
            color: white;
        }

        img {
            width: 10px;
            height: 15px;
            margin-top: -5px;
        }
    }

    &__separator {
        height: 1px;
        width: 100%;
        background-color: $grey--six;
    }
}

.upload-report-button {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    border: none;
    background-color: transparent;
    button {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-around;
        height: 50px;
        width: 50px;
        background-color: rgba($color: #ffffff, $alpha: 0.3);
        border: none; //solid 1px gray;
        border-radius: 25px;

        img {
            width: 25px;
            height: 25px;
            object-fit: contain;
            margin-right: 3px;
            margin-top: 3px;
        }
    }
    label {
        font-size: $font-size-14;
        font-weight: 400;
        color: white;
        margin-top: 3px;
    }
}

.upload-report-image {
    display: flex;
    align-items: left;
    flex-direction: row;
    justify-content: space-between;
    border: none;
    background-color: transparent;

    img {
        width: 48%;
        height: 48%;
        object-fit: contain;

        button {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-around;
            height: 50px;
            width: 50px;
            background-color: rgba($color: #ffffff, $alpha: 0.3);
            border: none; //solid 1px gray;
            border-radius: 25px;

            img {
                width: 25px;
                height: 25px;
                object-fit: contain;
                margin-right: 3px;
                margin-top: 3px;
            }
        }
    }
}

/*=====  End of postdetail  ======*/
