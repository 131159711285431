.common-cell {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    border-bottom: 1px solid #60606060;

    .logo {
        img {
            margin: 0 auto;
        }
    }
    .title {
        width: 100%;
        color: $grey--two;
        font-size: $font-size-14;
        font-weight: 400;
    }
    .sub-title {
        font-size: $font-size-17;
        font-weight: bold;
        color: $grey--two;
        margin-bottom: 40px;
    }
}
