.search_field-container {
    // display: flex;
    // align-items: center;
    // flex-direction: row;
    // justify-content: space-between;
    width: 100%;
    height: 40px;
    background-color: transparent;
    
    .search_field {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        background-color: #202020;
        width: 100%;
        height: 40px;
        border-radius: 20px;
        margin-bottom: 15px;

        .search_field_text {
            font-size: $font-size-13;
            line-height: 3;
            color: white;
            width: calc(100% - 20px);
            margin-left: 10px;
            border-width: 0px;
            border: none;
            background: transparent;
        }
    }

    .cancel-button {
        display: flex;
        font-size: $font-size-13;
        line-height: 2;
        color: white;
        width: 60px;
        height: 40px;
        background-color: transparent;
        border-width: 0px;
        border: none;
    }
}

.charts-options {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: left;
    width: 100%;
    height: 60px;
    margin-top: 0px;

    .dd-wrapper {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;   
        width:   150px;
        border: solid 1px gray;
        border-radius: 22px;
        height: 40px;
        min-height: 40px;

        .button {
            -webkit-appearance: none;
            -moz-appearance: none;
          
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
    
            font-size: $font-size-14;
            line-height: 1.3;
            color: white;
            background-color: black;
            border-width: 0px;
            width: 80%;
            height: 38px;
            min-height: 38px;
    
            .option {
                margin-left: 10px;
                background-color: black;
                color: white;
            }
        }
    }
}
