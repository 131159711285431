.skills-page-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    .skill-wrapper {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .skill-button {
            background-color: red;
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: center;
            background-color: $primary-button-background-color;
            height: 50px;
            border: none;
            border-radius: 8px;

            font-size: $font-size-13;
            font-weight: 450;
            line-height: 1;
            padding: 10px 0;
            color: white;
        }

        .left {
            width: 35%;
        }
        .right {
            width: calc(65% - 20px);
        }   

        .round {
            border-radius: 30px;
        }
        .color0 {
            background-color: gray;
        }
        .color1 {
            background-color: #78408a;
        }
        .color2 {
            background-color: #ec455a;
        }
        .color3 {
            background-color: #6743e0;
        }
        .color4 {
            background-color: #de87ac;
        }
        .color5 {
            background-color: #28262b;
        }
        .color6 {
            background-color: #78408a;
        }

        .genres_color1 {
            background-color: #df86ab;
        }
        .genres_color2 {
            background-color: #b03d50;
        }
        .genres_color3 {
            background-color: #463542;
        }
        .genres_color4 {
            background-color: #6ec3f6;
        }
        .genres_color5 {
            background-color: #eb455a;
        }
        .genres_color6 {
            background-color: #773f89;
        }
        .genres_color7 {
            background-color: #a5a4a1;
        }
        .genres_color8 {
            background-color: #d1b33b;
        }
        .genres_color9 {
            background-color: #6E1A1F;
        }
        .genres_color10 {
            background-color: #60BDAA;
        }
        
        .customize-skill-form {
            width: 100%;
            padding: 0px 8px;
            background-color: $primary-background-color;
            margin-top: -25px;
    
            &__single-field {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                label {
                    font-size: $font-size-14;
                    font-weight: 350;
                    margin-bottom: 10px;
                    color: white;
                }
                input,
                textarea {
                    font-size: $font-size-14;
    
                    display: block;
                    background-color: $grey--six;
                    height: 50px;
                    width: 100%;
                    padding: 10px;
                    margin-top: -10px;
    
                    color: $white;
                    text-align: center;
                    border: none;
                    border-radius: 8px;
                }
    
                ::placeholder {
                    opacity: 1; /* Firefox */
    
                    /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: $grey--four;
                    text-align: center;
                }
    
                :-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: $grey--four;
                }
    
                ::-ms-input-placeholder {
                    /* Microsoft Edge */
                    color: $grey--four;
                }
            }
        }
    }


    .description {
        font-family: $body-font-family;
        font-size: $font-size-14;
        font-weight: 450;
        color: white;
        text-align: center;
        width: 100%;
    }

    .sub-description {
        font-family: $body-font-family;
        font-size: $font-size-11;
        font-weight: 300;
        color: gray;
        text-align: center;
        width: 100%;
    }

}
