/*=============================================
=            profile            =
=============================================*/

/* profile header */

.profile {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.profile-header {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    background-color: transparent;
    width: 100%;
    &__buttons-container {
        width: 100%;
        height: 30px;
        display: flex;
        justify-content: space-between;

        .left-bar {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-around;
            background-color: transparent;
        }

        .button {
            width: 30px;
            height: 30px;
            min-width: 30px;
            min-height: 30px;
            border: none;

            display: flex;
            align-items: center;
            justify-content: space-around;
            background-color: transparent;

            img {
                width: 16px;
                height: 16px;
                min-width: 16px;
                min-height: 16px;
                object-fit: contain;
                background-color: transparent;
            }

            .reddot {
                width: 8px;
                height: 8px;
                min-width: 8px;
                min-height: 8px;
                background-color: red;
                margin-top: -15px;
                margin-left: -22px;
                border-radius: 4px;
            }
        }
    }

    &__image {
        width: 80px;
        height: 80px;
        img {
            width: 80px;
            height: 80px;
            min-width: 80px;
            min-height: 80px;
            border-radius: 40px;
            object-fit: cover;
            border-width: 1px;
            border-style: solid;
            border-color: #5e5964;
        }
    }
    &__content {
        flex-basis: calc(100% - 135px);
        width: 100%;
        .name {
            font-size: $font-size-16;
            font-weight: 700;
        }
    }
    .profile-info {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;

        &-block {
            &__username {
                font-size: $font-size-12;
                font-weight: 700;
                color: $white;
                text-align: center;
                margin-bottom: 5px;
            }
            &__usertype {
                font-size: $font-size-11;
                line-height: 1;
                color: $grey;
                text-align: center;
                margin-bottom: 15px;
            }
            &__usertag {
                font-size: $font-size-11;
                line-height: 1;
                color: $grey;
                text-align: center;
                margin-bottom: 15px;
            }
        }
    }

    .profile-connection {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;
        &__region {
            font-size: $font-size-12;
            color: $grey;
        }

        &__separator {
            width: 1px;
            height: 20px;
            background-color: $grey;
            margin-left: 10px;
        }

        &__icon {
            width: 20px;
            height: 20px;
            border-radius: 10px;
            margin-left: 10px;
            margin-right: 3px;
        }

        &__number {
            font-size: $font-size-12;
            color: $grey;
        }

        &__btn {
            font-size: $font-size-14;
            font-weight: bold;
            line-height: 1;
            text-align: center;
            display: block;

            width: 100vw;
            height: 50px;
            margin-left: 15px;
            margin-right: 15px;
            // margin-bottom: 40px;
            padding: 18px;

            color: $white;
            border-radius: 15px;
            background-color: $primary-button-background-color;
        }
    }

    .profile-buttons {
        margin-top: 20px;

        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;

        &__social {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-around;

            width: 50px;
            height: 50px;

            border-radius: 15px;
            background-color: $grey--nineteen;
            border-color: $grey--nineteen;
            margin-left: 5px;
            margin-right: 5px;

            .icon {
                display: block;
                max-width: 30px;
                max-height: 30px;
                width: auto;
                height: auto;
            }
        }

        &__primary {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-around;
            border: none;

            width: 38vw;
            height: 50px;
            border-radius: 25px;
            background-color: $primary-button-background-color;
            margin-left: 5px;
            margin-right: 5px;

            .text {
                font-size: $font-size-14;
                font-weight: bold;
                color: white;
            }
        }

        &__green {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-around;

            width: 38vw;
            height: 50px;
            border-radius: 25px;
            background-color: $forgot-button-background-color;
            margin-left: 5px;
            margin-right: 5px;
            border: none;

            .text {
                font-size: $font-size-14;
                font-weight: bold;
                color: white;
            }
        }
    }

    .profile-tabs {
        display: flex;
        align-items: center;
    }
}

/* profile body */

.profile-info-table {
    border-radius: 10px;
    background-color: $primary-background-color;
    .profile-info-block {
        display: flex;
        align-items: center;

        padding: 15px 30px;

        color: $black--four;
        border-bottom: 2px solid $grey--seven;

        &:last-child {
            border-bottom: none;
        }
        &__title {
            font-size: $font-size-14;
            font-weight: 500;

            flex-basis: 50%;
        }
        &__value {
            font-size: $font-size-12;
            font-weight: 300;

            flex-basis: 50%;

            text-align: right;
            a {
                svg {
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }
}

.edit-profile {
    width: 100%;
    background-color: $primary-background-color;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}

.edit-profile-header {
    width: 100%;
    padding: 20px 20px;
    background-color: $primary-background-color;
    display: flex;
    align-items: center;
    flex-direction: center;
    justify-content: space-between;

    .save-button {
        width: 100px;
        height: 44px;
        min-width: 30px;
        min-height: 30px;
        background-color: transparent;
        border-radius: 22px;
        border-width: 1px;
        border-style: solid;
        border-color: $primary-button-background-color;

        font-size: $font-size-13;
        font-weight: 450;
        line-height: 1;
        padding: 10px 0;
        color: $primary-button-background-color;
    }

    .title {
        width: 90%;
        padding-right: 30px;
        text-align: center;
        font-size: $font-size-16;
        color: white;
    }
}

.profile-image {
    background-color: transparent;
    display: flex;
    align-items: center;
    flex-direction: center;
    justify-content: space-evenly;
    width: 100%;
    height: 100px;

    .image {
        width: 100px;
        height: 100px;
        min-width: 16px;
        min-height: 16px;
        border-radius: 50px;
        object-fit: cover;
        background-color: transparent;
    }
}

.edit-profile-form {
    width: 100%;
    padding: 0px 20px;
    background-color: $primary-background-color;
    &__single-field {
        label {
            font-size: $font-size-14;

            margin-bottom: 20px;

            color: grey;
        }
        input,
        textarea {
            font-size: $font-size-14;

            display: block;
            background-color: $grey--six;
            height: 44px;
            width: 100%;
            padding: 10px;

            color: $white;
            border: none;
        }

        ::placeholder {
            opacity: 1; /* Firefox */

            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $grey--four;
            font-size: $font-size-12;
        }

        :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $grey--four;
        }

        ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $grey--four;
        }

        .check-container {
            display: flex;
            align-items: center;
            flex-direction: center;
            justify-content: space-between;

            .container {
                display: block;
                position: relative;
                padding-left: 28px;
                margin-bottom: 12px;
                cursor: pointer;
                font-size: $font-size-13;
                font-weight: 350;
                color: white;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }

            /* Hide the browser's default checkbox */
            .container input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }

            /* Create a custom checkbox */
            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 20px;
                width: 20px;
                border: 1px solid grey;
                border-radius: 3px;
            }

            /* On mouse-over, add a grey background color */
            //   .container:hover input ~ .checkmark {
            //     background-color: #ccc;
            //   }

            /* When the checkbox is checked, add a blue background */
            .container input:checked ~ .checkmark {
                background-color: $primary-button-background-color;
                border: 1px solid $primary-button-background-color;
            }

            /* Create the checkmark/indicator (hidden when not checked) */
            .checkmark:after {
                content: "";
                position: absolute;
                display: none;
            }

            /* Show the checkmark when checked */
            .container input:checked ~ .checkmark:after {
                display: block;
            }

            /* Style the checkmark/indicator */
            .container .checkmark:after {
                left: 6.5px;
                top: 3.5px;
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
    }

    &__button {
        font-size: $font-size-16;
        line-height: 1;

        width: 100%;
        padding: 15px 0;

        color: $white;
        border: none;
        background: none;
        background-color: $theme-color--default;
    }

    &__social {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        background-color: transparent;

        .title {
            font-size: $font-size-15;
            font-weight: 500;
            text-align: center;
            padding: 15px 0;
            margin-bottom: 0px;
            width: 100%;
            color: white;
            background-color: transparent;
        }

        .link {
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: baseline;
            justify-content: space-between;
            background-color: transparent;
            .label {
                font-size: $font-size-13;
                font-weight: 450;
                text-align: left;
                margin-top: 20px;
                // padding: 15px 0;
                color: white;
                background-color: transparent;
            }

            .button {
                width: 100px;
                height: 44px;
                min-width: 30px;
                min-height: 30px;
                background-color: transparent;
                border-radius: 22px;
                border-width: 1px;
                border-style: solid;
                border-color: $forgot-button-background-color;

                font-size: $font-size-13;
                font-weight: 450;
                line-height: 1;
                padding: 10px 0;
                color: white;
            }

            .button-white-border {
                width: 100px;
                height: 44px;
                min-width: 30px;
                min-height: 30px;
                background-color: transparent;
                border-radius: 22px;
                border-width: 1px;
                border-style: solid;
                border-color: white;

                font-size: $font-size-13;
                font-weight: 450;
                line-height: 1;
                padding: 10px 0;
                color: white;
            }
        }
    }
}

.normal-title {
    font-size: $font-size-13;
    width: 100%;
    text-align: left;
    color: white;
}

.pink-button-small {
    font-size: $font-size-10;
    line-height: 1;
    text-align: center;
    display: block;
    min-width: 80px;
    height: 30px;
    color: $white;
    border-radius: 15px;
    background-color: $primary-button-background-color;
    border: none;
}

.react-tabs-custom {
    -webkit-tap-highlight-color: transparent;
    &__tab-list {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;

        border-bottom: 1px solid #aaa;
        margin: 0 0 10px;
        padding: 0;
    }

    &__tab {
        display: inline-block;
        border: 1px solid transparent;
        border-bottom: none;
        bottom: -1px;
        position: relative;
        list-style: none;
        padding: 6px 12px;
        cursor: pointer;
        color: white;

        &--selected {
            background: #fff;
            border-color: #aaa;
            color: black;
            border-radius: 5px 5px 0 0;
        }

        &--disabled {
            color: GrayText;
            cursor: default;
        }

        &:focus {
            box-shadow: 0 0 5px hsl(208, 99%, 50%);
            border-color: hsl(208, 99%, 50%);
            outline: none;

            &:after {
                content: "";
                position: absolute;
                height: 5px;
                left: -4px;
                right: -4px;
                bottom: -5px;
                background: #fff;
            }
        }
    }

    &__tab-panel {
        display: none;

        &--selected {
            display: block;
        }
    }
}

.settings-cell {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border: solid 1px $black--eight;
    border-radius: 8px;

    label {
        font-size: $font-size-13;
        font-weight: 450;
        line-height: 1;
        padding: 10px 0;
        color: white;
        margin-left: 15px;
    }

    img {
        width: 15px;
        height: 15px;
        min-width: 15px;
        min-height: 15px;
        background-color: transparent;
        object-fit: contain;
        margin-right: 10px;
    }

    .checkmark-img {
        height: 30px;
        width: 30px;
        min-width: 30px;
        min-height: 30px;
        border: 1px solid grey;
        border-radius: 3px;
        margin-right: 15px;
    }

    .button {
        background-color: red;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        background-color: $primary-button-background-color;
        width: 80px;
        height: 30px;
        border-radius: 15px;

        label {
            font-size: $font-size-13;
            font-weight: 450;
            line-height: 1;
            padding: 10px 0;
            color: white;
        }
    }
}

.user-review-cell {
    display: flex;
    align-items: left;
    flex-direction: column;
    justify-content: space-between;
    background-color: #28252b;

    border-radius: 8px;
    border: solid 1px #5e5964;

    width: 100%;
    margin-left: 0%;
    margin-bottom: 15px;

    &__header {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;

        .author {
            margin-top: 10px;
            margin-left: 10px;
            margin-bottom: 5px;
            margin-right: 10px;

            .thumbnail {
                width: 30px;
                height: 30px;
                min-width: 30px;
                min-height: 30px;
                border-radius: 15px;
                object-fit: cover;
                margin-right: 10px;
                border-width: 1px;
                border-style: solid;
                border-color: #5e5964;
            }

            .user-name {
                font-size: $font-size-11;
                line-height: 1;
                text-align: left;
                display: inline-block;
                margin-top: 5px;
                color: white;
            }
        }

        .review-rating {
            display: flex;
            align-items: right;
            flex-direction: row;
            justify-content: flex-end;
            margin-right: 10px;

            img {
                width: 25px;
                height: 25px;
            }

            label {
                font-size: $font-size-13;
                line-height: 1;
                text-align: left;
                display: inline-block;
                align-self: flex-end;
                margin-bottom: 0px;
                color: white;
            }
        }
    }

    &__body {
        margin-top: 5px;
        margin-left: 15px;
        margin-right: 15px;
        .comment {
            font-size: $font-size-13;
            line-height: 1.3;
            color: white;
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;

        margin-top: -5px;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 5px;
        .time-stamp {
            font-size: $font-size-11;
            line-height: 1;
            text-align: left;
            display: inline-block;
            margin-top: 15px;
            color: $grey--four;
        }

        .name-label {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;

            margin-top: 15px;

            .song-name {
                font-size: $font-size-11;
                line-height: 1;
                text-align: left;
                display: inline-block;
                color: rgba($color: #ffffff, $alpha: 0.8);
            }

            .artist-name {
                font-size: $font-size-11;
                font-weight: 450;
                line-height: 1;
                text-align: left;
                display: inline-block;
                color: rgba($color: #ffffff, $alpha: 0.8);
            }

            .name-separator {
                margin-left: 5px;
                margin-right: 5px;
            }
        }
    }
}

.empty-label {
    width: 100%;
    font-size: $font-size-14;
    font-weight: 450;
    line-height: 1;
    text-align: center;
    display: inline-block;
    color: rgba($color: #ffffff, $alpha: 0.6);
    margin-top: 50px;
}


////Config react-input-phone-2
.flag-dropdown {
    border: none !important;
    // border-top-left-radius: 8px !important;
    // border-bottom-left-radius: 8px !important;
}

.react-tel-input .selected-flag {
    background-color: $grey--six !important;
    border: 1px !important;
    border-radius: 0 !important;
}

.react-tel-input .selected-flag .arrow {
    border-top: 4px solid white !important;
}

.react-tel-input .form-control {
    font-size: $font-size-14 !important;

    display: block;
    background-color: $grey--six !important;
    height: 44px !important;
    width: 100% !important;

    color: $white;
    border: none !important;
    border-radius: 0 !important;
}

.form-control:focus {
    box-shadow: none !important;
}
////End config react-input-phone-2


/*=====  End of profile  ======*/
