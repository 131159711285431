/*=============================================
=            Spacing            =
=============================================*/

.space-xy {
    &--10 {
        padding: 10px;
    }
    &--15 {
        padding: 15px;
    }
}

.space-y {
    &--10 {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    &--15 {
        padding-top: 15px;
        padding-bottom: 15px;
    }
    
    &--60 {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}

.space-mt {
    &---18 {
        margin-top: -18px;
    }
    &--10 {
        margin-top: 10px;
    }
    &--15 {
        margin-top: 15px;
    }
    &--20 {
        margin-top: 20px;
    }
    &--30 {
        margin-top: 30px;
    }
    &--40 {
        margin-top: 40px;
    }
    &--50 {
        margin-top: 50px;
    }
    &--60 {
        margin-top: 60px;
    }
    &--70 {
        margin-top: 70px;
    }
}

.space-ml {
    &--10 {
        margin-left: 10px;
    }
    &--15 {
        margin-left: 15px;
    }
    &--20 {
        margin-left: 20px;
    }
    &--30 {
        margin-left: 30px;
    }
    &--40 {
        margin-left: 40px;
    }
    &--70 {
        margin-left: 70px;
    }
}

.space-mr {
    &--10 {
        margin-right: 10px;
    }
    &--15 {
        margin-right: 15px;
    }
    &--20 {
        margin-right: 20px;
    }
    &--30 {
        margin-right: 30px;
    }
    &--40 {
        margin-right: 40px;
    }
    &--50 {
        margin-right: 50px;
    }
    &--70 {
        margin-right: 70px;
    }
}

.space-mb {
    &--5 {
        margin-bottom: 5px;
    }
    &--10 {
        margin-bottom: 10px;
    }
    &--15 {
        margin-bottom: 15px;
    }
    &--20 {
        margin-bottom: 20px;
    }
    &--25 {
        margin-bottom: 25px;
    }
    &--30 {
        margin-bottom: 30px;
    }
    &--40 {
        margin-bottom: 40px;
    }
    &--50 {
        margin-bottom: 50px;
    }
    &--70 {
        margin-bottom: 70px;
    }
    &--80 {
        margin-bottom: 80px;
    }
}

.space-mb-m {
    &--15 {
        margin-bottom: -15px;
    }
    &--20 {
        margin-bottom: -20px;
    }
}

.space-pb {
    &--15 {
        padding-bottom: 15px;
    }
    &--25 {
        padding-bottom: 25px;
    }
    &--30 {
        padding-bottom: 30px;
    }
    &--50 {
        padding-bottom: 50px;
    }
    &--60 {
        padding-bottom: 60px;
    }
    &--90 {
        padding-bottom: 90px;
    }
    &--120 {
        padding-bottom: 120px;
    }
    &--150 {
        padding-bottom: 150px;
    }
    &--180 {
        padding-bottom: 180px;
    }
    &--220 {
        padding-bottom: 220px;
    }
    &--280 {
        padding-bottom: 280px;
    }
    &--340 {
        padding-bottom: 340px;
    }
}

.space-pt {
    &--15 {
        padding-top: 15px;
    }
    &--25 {
        padding-top: 25px;
    }
    &--30 {
        padding-top: 30px;
    }
    &--50 {
        padding-top: 50px;
    }
    &--60 {
        padding-top: 60px;
    }
    &--70 {
        padding-top: 70px;
    }
    &--100 {
        padding-top: 100px;
    }
    &--200 {
        padding-top: 200px;
    }
}

.space-pl {
    &--15 {
        padding-left: 15px;
    }
    &--25 {
        padding-left: 25px;
    }
    &--30 {
        padding-left: 30px;
    }
    &--50 {
        padding-left: 50px;
    }
    &--60 {
        padding-left: 60px;
    }
    &--70 {
        padding-left: 70px;
    }
    &--80 {
        padding-left: 80px;
    }
    &--100 {
        padding-left: 100px;
    }
}

.space-pr {
    &--15 {
        padding-right: 15px;
    }
    &--25 {
        padding-right: 25px;
    }
    &--30 {
        padding-right: 30px;
    }
    &--50 {
        padding-right: 50px;
    }
    &--60 {
        padding-right: 60px;
    }
    &--70 {
        padding-right: 70px;
    }
    &--100 {
        padding-right: 100px;
    }
}
/*=====  End of Spacing  ======*/
