
/*=============================================
=            breadcrumb            =
=============================================*/

.breadcrumb-area {
    .page-title {
        font-size: $font-size-14;
        color: $white;
    }
    .back-link {
        font-weight: 500;

        color: $white;
        i {
            margin-right: 3px;
        }
    }
}


/*=====  End of breadcrumb  ======*/

